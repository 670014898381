import React, { FocusEventHandler, forwardRef } from 'react';
import {
  ACTION_ICON_SVG,
  VOLUME_ICON_STYLE,
  DEFAULT_FILL_WHITE,
  PANEL_SVG_SIZE,
  NAV2_ICON_SVG,
  THEME_ICON_SVG,
  CHANNEL_OR_PAGE_CREATION_ICON_SVG,
  PANEL_V2_SVG_SIZE,
  PANEL_V2_ACTION_SVG_SIZE,
  USE_ADMIN_THEME_COLORS_ON_SVG,
} from 'style/mixins';
import {
  ACCENT_PRIMARY,
  SURFACE_3,
  MAESTRO_WHITE,
  SURFACE_2,
  MAESTRO_BLACK,
} from 'style/constants';
import { shadeHexColor, lightOrDarkColor } from 'utils';
import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  IStyledProps,
  ThemedStyledProps,
} from 'styled-components';
import warning from 'warning';
import captionsSvg from 'assets/icons/captions.svg';
import checkedSvg from 'assets/icons/checked.svg';
import closeThinSvg from 'assets/icons/close-thin.svg';
import defaultChannelSvg from 'assets/icons/default-video-card.svg';
import modalRichTextSvg from 'assets/icons/modal-rich-text.svg';
import modalShopifyBlockSvg from 'assets/icons/modal-shopify-block.svg';
import modalEventCalendarSvg from 'assets/icons/modal-event-calendar.svg';
import modalEmbedCodeSvg from 'assets/icons/modal-embed-code.svg';
import modalFortniteLeaderboard from 'assets/icons/modal-leaderboard.svg';
import modalHeroBannerSvg from 'assets/icons/modal-hero-banner.svg';
import modalVideoSpotlightSvg from 'assets/icons/modal-video-spotlight.svg';
import modalChannelNavigatorSvg from 'assets/icons/modal-channel-navigator.svg';
import modalPlaylistSvg from 'assets/icons/modal-playlist.svg';
import modalTaggedVideoSvg from 'assets/icons/modal-tagged-video.svg';
import modalImageGallerySvg from 'assets/icons/modal-image-gallery.svg';
import modalTextBannerSvg from 'assets/icons/modal-text-banner.svg';
import modalFAQSvg from 'assets/icons/modal-faq.svg';
import modalImageAndTextSvg from 'assets/icons/add-page-block-image-and-text.svg';
import pencilSquareSvg from 'assets/icons/pencil-square.svg';
import accessControlLoginSvg from 'assets/icons/access-control-login.svg';
import accessControlTicketSubscriptionSvg from 'assets/icons/access-control-ticket-subscription.svg';
import accessControlPasswordSvg from 'assets/icons/access-control-password.svg';
import accessControlAmazonSvg from 'assets/icons/access-control-amazon.svg';
import addToCalGoogleSvg from 'assets/icons/addToCalGoogle.svg';
import addToCalICalSvg from 'assets/icons/addToCalICal.svg';
import addToCalYahooSvg from 'assets/icons/addToCalYahoo.svg';
import arrowUpSvg from 'assets/icons/arrow-up.svg';
import arrowBottom from 'assets/icons/arrow-bottom.svg';
import bellSvg from 'assets/icons/bell.svg';
import playlistCameraSvg from 'assets/icons/playlistCamera.svg';
import cardLargeSvg from 'assets/icons/cardLg.svg';
import channelsSvg from 'assets/icons/channels.svg';
import checkmarkSvg from 'assets/icons/checkmark.svg';
import chatStar from 'assets/icons/chat-star.svg';
import fortniteEliminations from 'assets/icons/fortnite-eliminations.svg';
import fortnitePlayer from 'assets/icons/fortnite-player.svg';
import fortnitePoints from 'assets/icons/fortnite-points.svg';
import fortnitePosition from 'assets/icons/fortnite-position.svg';
import fortniteRank from 'assets/icons/fortnite-rank.svg';
import fortniteRoyals from 'assets/icons/fortnite-royal.svg';
import fortniteStar from 'assets/icons/fortnite-star.svg';
import gridSvg from 'assets/icons/grid.svg';
import groupLargeSvg from 'assets/icons/groupLg.svg';
import homeSvg from 'assets/icons/home.svg';
import hourSvg from 'assets/icons/hour.svg';
import channelIconSvg from 'assets/icons/icon_large_channel.svg';
import landingPageIconSvg from 'assets/icons/icon_large_landing_page.svg';
import menuSvg from 'assets/icons/menu.svg';
import multipleChoiceLargeSvg from 'assets/icons/multipleChoiceLg.svg';
import multipleChoiceV2Svg from 'assets/icons/multipleChoiceV2.svg';
import overlayLargeSvg from 'assets/icons/overlayLg.svg';
import pageLargeSvg from 'assets/icons/pageLg.svg';
import panelLargeSvg from 'assets/icons/panelLg.svg';
import panelsSvg from 'assets/icons/panels.svg';
import personLargeSvg from 'assets/icons/personLg.svg';
import playTriangleSvg from 'assets/icons/playTriangle.svg';
import playLargeSvg from 'assets/icons/playLarge.svg';
import playlistLargeSvg from 'assets/icons/playlistLg.svg';
import pointer from 'assets/icons/pointer_hand.svg';
import portalSvg from 'assets/icons/portal.svg';
import productLargeSvg from 'assets/icons/productLg.svg';
import pubgDbnoSvg from 'assets/icons/pubg-dbno.svg';
import pubgDmgSvg from 'assets/icons/pubg-dmg.svg';
import questLargeSvg from 'assets/icons/questLg.svg';
import shopifyBackSvg from 'assets/icons/shopify-back.svg';
import shoppingCartSvg from 'assets/icons/shopping-cart.svg';
import thumbtackSvg from 'assets/icons/thumbtack.svg';
import analyticsUpArrowSvg from 'assets/icons/analytics-up-arrow.svg';
import analyticsDownArrowSvg from 'assets/icons/analytics-down-arrow.svg';
import translatedByGoogleSvg from 'assets/icons/translatedByGoogle.svg';
import videoLargeSvg from 'assets/icons/videoLg.svg';
import keySvg from 'assets/icons/key.svg';
import chatSvg from 'assets/icons/chat.svg';
import chatV2Svg from 'assets/icons/chat-v2.svg';
import tipsSVG from 'assets/icons/tips.svg';
import tipsV2SVG from 'assets/icons/tips-v2.svg';
import tipsEyeShowSVG from 'assets/icons/tips-eye-show.svg';
import tipsLogDollarSVG from 'assets/icons/tip-log-dollar-icon.svg';
import tipsEyeHideSVG from 'assets/icons/tips-eye-hide.svg';
import highlightSvg from 'assets/icons/highlight.svg';
import lowerThirdv2Svg from 'assets/icons/lowerThird.svg';
import pollResultSvg from 'assets/icons/pollResult.svg';
import transparentCircledSvg from 'assets/icons/transparent-circled.svg';
import pollSvg from 'assets/icons/poll.svg';
import raffleSvg from 'assets/icons/raffle.svg';
import tweetSvg from 'assets/icons/tweet.svg';
import personSvg from 'assets/icons/person.svg';
import QuestSvgv2 from 'assets/icons/questv2.svg';
import QuestV3Svg from 'assets/icons/questv3.svg';
import qandaSvg from 'assets/icons/qanda.svg';
import imageSvg from 'assets/icons/image.svg';
import imageV3Svg from 'assets/icons/imageV3.svg';
import scheduleSvg from 'assets/icons/schedule.svg';
import scheduleV3Svg from 'assets/icons/scheduleV3.svg';
import channelSelectSvg from 'assets/icons/channelSelect.svg';
import channelSelectV3Svg from 'assets/icons/channelSelectV3.svg';
import channelSelectV3OutlineSvg from 'assets/icons/channelSelectV3-outline.svg';
import PlaylistSvgv2 from 'assets/icons/playlistv2.svg';
import PlaylistSvgV3 from 'assets/icons/playlistv3.svg';
import twitchv2Svg from 'assets/icons/analytics-twitch.svg';
import twitchv3Svg from 'assets/icons/twitchv3.svg';
import checkv2Svg from 'assets/icons/checkv2.svg';
import iframeSvg from 'assets/icons/iframe.svg';
import iframeV3Svg from 'assets/icons/iframev3.svg';
import leaderboardSvg from 'assets/icons/leaderboard.svg';
import leaderboardV3Svg from 'assets/icons/leaderboardV3.svg';
import donationsSvg from 'assets/icons/donations.svg';
import triviaSvg from 'assets/icons/trivia.svg';
import magnetSvg from 'assets/icons/magnet.svg';
import analyticsChartArrowSvg from 'assets/icons/analyticsChartArrow.svg';
import topLineMetricsSVG from 'assets/icons/topLineMetrics.svg';
import videoPlaySVG from 'assets/icons/VideoPlay.svg';
import muteSVG from 'assets/icons/mute.svg';
import AnalyticsChartArrowSvg from 'assets/icons/analyticsChartArrow.svg';
import cancelBoldSvg from 'assets/icons/cancel-bold.svg';
import analyticsInfoSvg from 'assets/icons/analytics-info.svg';
import analyticsViewershipEyeSvg from 'assets/icons/analyticsEye.svg';
import analyticsClockSvg from 'assets/icons/analytics-clock.svg';
import analyticsEyeSvg from 'assets/icons/analytics-eye.svg';
import analyticsMoneySvg from 'assets/icons/analytics-money.svg';
import analyticsPersonSvg from 'assets/icons/analytics-person.svg';
import analyticsPersonOutlinedSvg from 'assets/icons/analytics-person-outlined.svg';
import analyticsReturnSvg from 'assets/icons/analytics-return.svg';
import analyticsClockDotSvg from 'assets/icons/analytics-clock-dot.svg';
import analyticsClockPlusSvg from 'assets/icons/analytics-clock-plus.svg';
import analyticsForwardSvg from 'assets/icons/analytics-forward.svg';
import analyticsPersonBackSvg from 'assets/icons/analytics-person-back.svg';
import adminbar3rdPartySvg from 'assets/icons/adminbar-3rd-party.svg';
import adminbarAchievementsSvg from 'assets/icons/adminbar-achievements.svg';
import adminbarAdminsSvg from 'assets/icons/adminbar-admins.svg';
import adminbarBillingSvg from 'assets/icons/adminbar-billing.svg';
import adminbarChannelsSvg from 'assets/icons/adminbar-channels.svg';
import adminbarDeveloperSvg from 'assets/icons/adminbar-developer.svg';
import adminbarColorsSvg from 'assets/icons/adminbar-colors.svg';
import adminbarCommunitySvg from 'assets/icons/adminbar-community.svg';
import adminbarConcurrentsSvg from 'assets/icons/adminbar-concurrents.svg';
import adminbarCustomerProfileSvg from 'assets/icons/adminbar-customer-profile.svg';
import adminbarDesignSvg from 'assets/icons/adminbar-design.svg';
import adminbarDesktopSvg from 'assets/icons/adminbar-desktop.svg';
import adminbarEditorSvg from 'assets/icons/adminbar-editor.svg';
import adminbarEngagementSvg from 'assets/icons/adminbar-engagement.svg';
import adminbarEntitlementsSvg from 'assets/icons/adminbar-entitlements.svg';
import adminbarFontsSvg from 'assets/icons/adminbar-fonts.svg';
import adminbarGtSvg from 'assets/icons/adminbar-gt.svg';
import adminbarHelpSvg from 'assets/icons/adminbar-help.svg';
import adminbarIconsSvg from 'assets/icons/adminbar-icons.svg';
import adminbarInsightsSvg from 'assets/icons/adminbar-insights.svg';
import folderOutlineSvg from 'assets/icons/folder-outline.svg';
import adminbarLinkSvg from 'assets/icons/adminbar-link.svg';
import adminbarLocalizationSvg from 'assets/icons/adminbar-localization.svg';
import adminbarLogInSvg from 'assets/icons/adminbar-login.svg';
import adminbarLtSvg from 'assets/icons/adminbar-lt.svg';
import navArrowSvg from 'assets/icons/adminbar-lt.svg';
import adminbarMobileSvg from 'assets/icons/adminbar-mobile.svg';
import adminbarOverlaysSvg from 'assets/icons/adminbar-overlays.svg';
import adminbarPanelsSvg from 'assets/icons/adminbar-panels.svg';
import adminbarRetentionSvg from 'assets/icons/adminbar-retention.svg';
import adminbarEarningsSvg from 'assets/icons/adminbar-earnings.svg';
import adminbarStreamingSvg from 'assets/icons/adminbar-stream.svg';
import adminbarSuperAdminSvg from 'assets/icons/adminbar-super-admin.svg';
import adminbarTextSvg from 'assets/icons/adminbar-text.svg';
import adminbarTrendsSvg from 'assets/icons/adminbar-trends.svg';
import adminbarUserProfilesSvg from 'assets/icons/adminbar-user-profiles.svg';
import adminbarVideoPlayerSvg from 'assets/icons/adminbar-video-player.svg';
import adminbarVideosSvg from 'assets/icons/adminbar-videos.svg';
import adminbarWelcomeScreenSvg from 'assets/icons/adminbar-welcome-screen.svg';
import designtabColorsSvg from 'assets/icons/designtab-colors.svg';
import designtabDesktopSvg from 'assets/icons/designtab-desktop.svg';
import designtabFontsSvg from 'assets/icons/designtab-fonts.svg';
import linkOutlineSvg from 'assets/icons/link-outline.svg';
import designtabMobileSvg from 'assets/icons/designtab-mobile.svg';
import designtabTextSvg from 'assets/icons/designtab-text.svg';
import designUpArrowSvg from 'assets/icons/design-up-arrow.svg';
import devicesSVG from 'assets/icons/devices.svg';
import phoneSVG from 'assets/icons/phone.svg';
import closeThickSVG from 'assets/icons/close-thick.svg';
import crashPageSVG from 'assets/icons/crash-page-icon.svg';
import notFoundPageSVG from 'assets/icons/not-found-icon.svg';
import layoutContentBelowSvg from 'assets/icons/layout-content-below.svg';
import layoutContentInsideSvg from 'assets/icons/layout-content-inside.svg';
import settingsOutlinedSvg from 'assets/icons/settings-outlined.svg';
import flowSliderSvg from 'assets/icons/flow-slider.svg';
import flowStackSvg from 'assets/icons/flow-stack.svg';
import monitorSVG from 'assets/icons/monitor.svg';
import checkboxCheckSvg from 'assets/icons/checkbox-check.svg';
import engagedAudienceSVG from 'assets/icons/engagedAudience.svg';
import totalAudienceSVG from 'assets/icons/totalAudience.svg';
import uploadPictureSvg from 'assets/icons/upload-picture.svg';
import panelsProfPicEllipseSvg from 'assets/icons/panels-prof-pic-ellipse.svg';
import warningSvg from 'assets/icons/warning.svg';
import cardsSvg from 'assets/icons/cards.svg';
import newspaperSvg from 'assets/icons/newspaper.svg';
import chatv2Svg from 'assets/icons/analytics-chat.svg';
import cancelThinSvg from 'assets/icons/cancel-thin.svg';
import heartSvg from 'assets/icons/heart.svg';
import pencilSvg from 'assets/icons/pencil.svg';
import gamerSvg from 'assets/icons/gamer.svg';
import linkSvg from 'assets/icons/link.svg';
import picturesSvg from 'assets/icons/pictures.svg';
import barChartSvg from 'assets/icons/bar-chart.svg';
import basketSvg from 'assets/icons/basket.svg';
import multicameraSvg from 'assets/icons/multicamera.svg';
import checkSvg from 'assets/icons/check.svg';
import listBulletSvg from 'assets/icons/list-bullet.svg';
import helpSvg from 'assets/icons/help.svg';
import awardSvg from 'assets/icons/award.svg';
import ticketSvg from 'assets/icons/ticket.svg';
import calendarSvg from 'assets/icons/calendar.svg';
import twitchSvg from 'assets/icons/twitch.svg';
import twitterSvg from 'assets/icons/twitter.svg';
import videocamSvg from 'assets/icons/videocam.svg';
import librarySvg from 'assets/icons/library.svg';
import librarySaveSvg from 'assets/icons/library-save.svg';
import catalogSvg from 'assets/icons/catalog.svg';
import plusThinSvg from 'assets/icons/plus-thin.svg';
import plusCircleSvg from 'assets/icons/plus-circle.svg';
import successCheckmarkSvg from 'assets/icons/success-checkmark.svg';
import sadShoppingCartSvg from 'assets/icons/sad-shopping-cart.svg';
import deleteSvg from 'assets/icons/delete.svg';
import arrowLeftSvg from 'assets/icons/arrow-left.svg';
import arrowRightSvg from 'assets/icons/arrow-right.svg';
import channelSelectArrowRightSvg from 'assets/icons/channel-select-arrow-right.svg';
import channelSelectArrowLeftSvg from 'assets/icons/channel-select-arrow-left.svg';
import twitterFilledSvg from 'assets/icons/twitter-filled.svg';
import retweetSvg from 'assets/icons/retweet.svg';
import leftArrowGradSvg from 'assets/icons/left-arrow-grad.svg';
import rightArrowGradSvg from 'assets/icons/right-arrow-grad.svg';
import upArrowGradSvg from 'assets/icons/up-arrow-grad.svg';
import downArrowGradSvg from 'assets/icons/down-arrow-grad.svg';
import downArrowSvg from 'assets/icons/down-arrow.svg';
import trashSvg from 'assets/icons/trash.svg';
import trashV2Svg from 'assets/icons/trash-v2.svg';
import cancelShortSvg from 'assets/icons/cancel-short.svg';
import eyeFilledSvg from 'assets/icons/eye-filled.svg';
import correctCircleSvg from 'assets/icons/correct-circle.svg';
import wrongCircleSvg from 'assets/icons/wrong-circle.svg';
import cursorHandSvg from 'assets/icons/cursor-hand.svg';
import eyeHiddenSvg from 'assets/icons/eye-hidden.svg';
import megaphoneSvg from 'assets/icons/megaphone.svg';
import facebookSquaredSvg from 'assets/icons/facebook-squared.svg';
import creditCardSvg from 'assets/icons/credit-card.svg';
import fullscreenSvg from 'assets/icons/fullscreen.svg';
import exitFullscreenSvg from 'assets/icons/exit-fullscreen.svg';
import hideSidebarSvg from 'assets/icons/hide-sidebar.svg';
import showSidebarSvg from 'assets/icons/show-sidebar.svg';
import theaterSvg from 'assets/icons/theater.svg';
import volumeHighSvg from 'assets/icons/volume-high.svg';
import volumeLowSvg from 'assets/icons/volume-low.svg';
import volumeMutedSvg from 'assets/icons/volume-muted.svg';
import emailConfirmed from 'assets/icons/email_confirmed.svg';
import exclamationCircleSvg from 'assets/icons/exclamation-circle.svg';
import mailSvg from 'assets/icons/mail.svg';
import accessCodeSvg from 'assets/icons/access-code.svg';
import accessCodeImgPlaceholderSvg from 'assets/icons/access-code-img-placeholder.svg';
import changeBackgroundSvg from 'assets/icons/change-background-icon.svg';
import lockSvg from 'assets/icons/lock.svg';
import shopifyColoredSvg from 'assets/icons/shopify-colored.svg';
import fbCircleColoredSvg from 'assets/icons/fb-circle-colored.svg';
import gAnalyticsColoredSvg from 'assets/icons/g-analytics-colored.svg';
import lowerThirdSvg from 'assets/icons/lower-third.svg';
import pollResultsSvg from 'assets/icons/poll-results.svg';
import channelMockSvg from 'assets/icons/channel-mock.svg';
import landingMockSvg from 'assets/icons/landing-mock.svg';
import maestroBlankSvg from 'assets/icons/maestro-blank.svg';
import cookieSvg from 'assets/icons/cookie.svg';
import helpIconSvg from 'assets/icons/helpIcon.svg';
import userSvg from 'assets/icons/user.svg';
import userXSvg from 'assets/icons/user-x.svg';
import userPlusSvg from 'assets/icons/user-plus.svg';
import singleUserSvg from 'assets/icons/singleUser.svg';
import dashSvg from 'assets/icons/dash.svg';
import addImageSvg from 'assets/icons/add-image.svg';
import addVideoSvg from 'assets/icons/add-video.svg';
import searchLibrarySvg from 'assets/icons/search-library.svg';
import cleanDownGradArrowSvg from 'assets/icons/clean-down-grad-arrow.svg';
import locationSvg from 'assets/icons/location.svg';
import clockSvg from 'assets/icons/clock.svg';
import pauseSvg from 'assets/icons/pause.svg';
import rectOutlineSvg from 'assets/icons/rect-outline.svg';
import rectSolidSvg from 'assets/icons/rect-solid.svg';
import airplaySvg from 'assets/icons/airplay.svg';
import airplayDisconnectSvg from 'assets/icons/airplay-disconnect.svg';
import shareSvg from 'assets/icons/share.svg';
import twitterCircleColoredSvg from 'assets/icons/twitter-circle-colored.svg';
import twitterCircleSvg from 'assets/icons/twitter-circle.svg';
import chromecastSvg from 'assets/icons/chromecast.svg';
import chromecastDisconnectSvg from 'assets/icons/chromecast-disconnect.svg';
import checkmarkCircleSvg from 'assets/icons/checkmark-circle.svg';
import screenSvg from 'assets/icons/screen.svg';
import studioLogoSvg from 'assets/icons/studio-logo.svg';
import arrowGradLeftCroppedSvg from 'assets/icons/arrow-grad-left-cropped.svg';
import personPullingStringSvg from 'assets/icons/person-pulling-string.svg';
import channelSelectorSvg from 'assets/icons/channelSelector.svg';
import unionSvg from 'assets/icons/union.svg';
import externalLinkSvg from 'assets/icons/external-link.svg';
import externalLink2Svg from 'assets/icons/external-link-2.svg';
import pageSvg from 'assets/icons/page.svg';
import pageOutlineSvg from 'assets/icons/page-outline.svg';
import homeFilledSvg from 'assets/icons/home-filled.svg';
import circledCheckmarkSvg from 'assets/icons/circled-checkmark.svg';
import circledCheckmarkUnfilledSvg from 'assets/icons/circle-checkmark-unfilled.svg';
import infoSvg from 'assets/icons/info.svg';
import downloadSvg from 'assets/icons/download.svg';
import copySvg from 'assets/icons/copy-icon.svg';
import resendSvg from 'assets/icons/resend.svg';
import chartIconSvg from 'assets/icons/chart-icon.svg';
import ticketsIconSvg from 'assets/icons/tickets-icon.svg';
import subscriptionsIconSvg from 'assets/icons/subscriptions-icon.svg';
import merchSvg from 'assets/icons/merch.svg';
import earningsSvg from 'assets/icons/earnings.svg';
import inMaintenance from 'assets/icons/admin-in-maintenance.svg';
import imgUploadSvg from 'assets/icons/img-upload.svg';
import nav2ChannelsSvg from 'assets/icons/nav2-channels.svg';
import nav2LibrarySvg from 'assets/icons/nav2-library.svg';
import nav2AnalyticsSvg from 'assets/icons/nav2-analytics.svg';
import nav2CommunitySvg from 'assets/icons/nav2-community.svg';
import nav2SubsSvg from 'assets/icons/nav2-subs.svg';
import nav2SettingsSvg from 'assets/icons/nav2-settings.svg';
import nav2WizardSvg from 'assets/icons/nav2-wizard.svg';
import nav2HelpSvg from 'assets/icons/nav2-help.svg';
import nav2ThirdPartySvg from 'assets/icons/nav2-third-party.svg';
import nav2SiteAdsSvg from 'assets/icons/nav2SiteAds.svg';
import nav2AdminsSvg from 'assets/icons/nav2-admins.svg';
import nav2BillingSvg from 'assets/icons/nav2-billing.svg';
import nav2DeveloperSvg from 'assets/icons/nav2-developer.svg';
import nav2DomainSvg from 'assets/icons/nav2-domain.svg';
import nav2IconsSvg from 'assets/icons/nav2-icons.svg';
import nav2LocalizationSvg from 'assets/icons/nav2-localization.svg';
import nav2LoginSvg from 'assets/icons/nav2-login.svg';
import nav2ReceiptsSvg from 'assets/icons/nav2-receipts.svg';
import nav2SuperAdminSvg from 'assets/icons/nav2-super-admin.svg';
import nav2UserProfileSvg from 'assets/icons/nav2-user-profile.svg';
import nav2EarningsSvg from 'assets/icons/nav2-earnings.svg';
import nav2EngagementSvg from 'assets/icons/nav2-engagement.svg';
import nav2InsightsSvg from 'assets/icons/nav2-insights.svg';
import nav2RetentionSvg from 'assets/icons/nav2-retention.svg';
import nav2TrendsSvg from 'assets/icons/nav2-trends.svg';
import nav2VideosSvg from 'assets/icons/nav2-videos.svg';
import nav2ContentSvg from 'assets/icons/nav2-content.svg';
import nav2EditorSvg from 'assets/icons/nav2-editor.svg';
import nav2AccessSvg from 'assets/icons/nav2-access.svg';
import nav2OverlaysSvg from 'assets/icons/nav2-overlays.svg';
import nav2DesignSvg from 'assets/icons/nav2-design.svg';
import nav2PanelsSvg from 'assets/icons/nav2-panels.svg';
import nav2StreamingSvg from 'assets/icons/nav2-streaming.svg';
import nav2AccessStrokeSvg from 'assets/icons/nav2-access-stroke.svg';
import nav2ContentStrokeSvg from 'assets/icons/nav2-content-stroke.svg';
import nav2DesignStrokeSvg from 'assets/icons/nav2-design-stroke.svg';
import nav2SettingsStrokeSvg from 'assets/icons/nav2-settings-stroke.svg';
import colorSvg from 'assets/icons/color-icon.svg';
import refreshSvg from 'assets/icons/refresh-icon.svg';
import plusSvg from 'assets/icons/plus.svg';
import plusTinySvg from 'assets/icons/plus-tiny.svg';
import nav2AchievementsSvg from 'assets/icons/nav2-achievements.svg';
import circledHelpSvg from 'assets/icons/circled-help.svg';
import liveVideoSvg from 'assets/icons/live-video.svg';
import liveShoppingSvg from 'assets/icons/live-shopping.svg';
import wizardOptionSearchSvg from 'assets/icons/wizard-option-search.svg';
import freeSvg from 'assets/icons/free.svg';
import desktopFilledSvg from 'assets/icons/desktop-filled.svg';
import colorFillSvg from 'assets/icons/color-fill.svg';
import colorPickerSvg from 'assets/icons/color-picker.svg';
import opacitySvg from 'assets/icons/opacity.svg';
import channelFavoriteSvg from 'assets/icons/channel-favorite.svg';
import threeDotsSvg from 'assets/icons/three-dots.svg';
import cogV2Svg from 'assets/icons/cog-v2.svg';
import textAlignmentLeftSvg from 'assets/icons/text-alignment-left.svg';
import textAlignmentCenterSvg from 'assets/icons/text-alignment-center.svg';
import textAlignmentRightSvg from 'assets/icons/text-alignment-right.svg';
import layoutLeftSvg from 'assets/icons/layout-left.svg';
import layoutStackedSvg from 'assets/icons/layout-stacked.svg';
import layoutRightSvg from 'assets/icons/layout-right.svg';
import layoutWithVideoLeftSvg from 'assets/icons/layout-with-video-left.svg';
import layoutWithVideoRightSvg from 'assets/icons/layout-with-video-right.svg';
import layoutWithVideoStackedSvg from 'assets/icons/layout-with-video-stacked.svg';
import shoppingBagSvg from 'assets/icons/shopping-bag.svg';
import stripeLogoSvg from 'assets/icons/stripeLogo.svg';
import dragAndDropVideoSvg from 'assets/icons/drag-drop-video.svg';
import dragAndDropVideoErrorSvg from 'assets/icons/drag-drop-video-error.svg';
import peopleSvg from 'assets/icons/people.svg';
import arrowDropDownUpSvg from 'assets/icons/arrow-drop-down-up.svg';
import arrowDropDownDownSvg from 'assets/icons/arrow-drop-down-down.svg';
import uploadFileSvg from 'assets/icons/upload-file.svg';
import amazonSvg from 'assets/icons/amazon.svg';
import removeIconOutlineSvg from 'assets/icons/remove-icon-outline.svg';
import questionMarkFilledSvg from 'assets/icons/questionmark-filled.svg';
import mobileHomeOutlineSvg from 'assets/icons/mobile-home-outline.svg';
import mobileSearchSvg from 'assets/icons/mobile-search.svg';
import mobileProfileFilledSvg from 'assets/icons/mobile-profile-filled.svg';
import alignLeft from 'assets/icons/rich-text-editor-v3/alignLeft.svg';
import alignCenter from 'assets/icons/rich-text-editor-v3/alignCenter.svg';
import alignRight from 'assets/icons/rich-text-editor-v3/alignRight.svg';
import fullWidth from 'assets/icons/rich-text-editor-v3/fullWidth.svg';
import addTableNodeSvg from 'assets/icons/rich-text-editor-v3/add-table-node.svg';
import rotate from 'assets/icons/rotate.svg';

const PAGE_BLOCKS_STYLE = css`
  & > svg {
    width: 147.5px;
    height: 96px;

    ${USE_ADMIN_THEME_COLORS_ON_SVG}
  }
`;

import { hasOwn } from 'utils';

// TODO: Styling these is a royal pain. SVG items need tags or classes to make this remotely
//       ergonomic.
export const SVG_ICON_MAP: {
  [key: string]: string;
} = {
  rotate,
  captions: captionsSvg,
  checked: checkedSvg,
  closeThin: closeThinSvg,
  defaultChannel: defaultChannelSvg,
  modalHeroBanner: modalHeroBannerSvg,
  modalRichText: modalRichTextSvg,
  modalShopifyBlock: modalShopifyBlockSvg,
  modalEventCalendar: modalEventCalendarSvg,
  modalEmbedCode: modalEmbedCodeSvg,
  modalFortniteLeaderboard,
  modalVideoSpotlight: modalVideoSpotlightSvg,
  modalChannelNavigator: modalChannelNavigatorSvg,
  modalPlaylist: modalPlaylistSvg,
  modalTaggedVideo: modalTaggedVideoSvg,
  modalImageGallery: modalImageGallerySvg,
  modalTextBanner: modalTextBannerSvg,
  modalFAQ: modalFAQSvg,
  modalImageAndText: modalImageAndTextSvg,
  pencilSquare: pencilSquareSvg,
  accessControlLogin: accessControlLoginSvg,
  accessControlTicketSubscription: accessControlTicketSubscriptionSvg,
  accessControlPassword: accessControlPasswordSvg,
  accessControlAmazon: accessControlAmazonSvg,
  addToCalGoogle: addToCalGoogleSvg,
  addToCalICal: addToCalICalSvg,
  addToCalYahoo: addToCalYahooSvg,
  arrowLeft: arrowLeftSvg,
  channelSelectArrowLeft: channelSelectArrowLeftSvg,
  arrowRight: arrowRightSvg,
  channelSelectArrowRight: channelSelectArrowRightSvg,
  adminbar3rdParty: adminbar3rdPartySvg,
  adminbarAchievements: adminbarAchievementsSvg,
  adminbarAdmins: adminbarAdminsSvg,
  adminbarBilling: adminbarBillingSvg,
  adminbarChannels: adminbarChannelsSvg,
  adminbarCodeInjection: adminbarDeveloperSvg,
  adminbarColors: adminbarColorsSvg,
  adminbarCommunity: adminbarCommunitySvg,
  adminbarConcurrents: adminbarConcurrentsSvg,
  adminbarCustomerProfile: adminbarCustomerProfileSvg,
  adminbarDesign: adminbarDesignSvg,
  adminbarDesktop: adminbarDesktopSvg,
  adminbarDeveloper: adminbarDeveloperSvg,
  adminbarEditor: adminbarEditorSvg,
  adminbarEngagement: adminbarEngagementSvg,
  adminbarEntitlements: adminbarEntitlementsSvg,
  adminbarFonts: adminbarFontsSvg,
  adminbarGt: adminbarGtSvg,
  adminbarHelp: adminbarHelpSvg,
  adminbarIcons: adminbarIconsSvg,
  adminbarInsights: adminbarInsightsSvg,
  folderOutline: folderOutlineSvg,
  adminbarLink: adminbarLinkSvg,
  adminbarLocalization: adminbarLocalizationSvg,
  adminbarLogIn: adminbarLogInSvg,
  adminbarLt: adminbarLtSvg,
  navArrow: navArrowSvg,
  adminbarMobile: adminbarMobileSvg,
  adminbarOverlays: adminbarOverlaysSvg,
  adminbarPanels: adminbarPanelsSvg,
  adminbarRetention: adminbarRetentionSvg,
  adminbarEarnings: adminbarEarningsSvg,
  adminbarStreaming: adminbarStreamingSvg,
  adminbarSuperAdmin: adminbarSuperAdminSvg,
  adminbarText: adminbarTextSvg,
  adminbarTrends: adminbarTrendsSvg,
  adminbarUserProfiles: adminbarUserProfilesSvg,
  adminbarVideoPlayer: adminbarVideoPlayerSvg,
  adminbarVideos: adminbarVideosSvg,
  adminbarWelcomeScreen: adminbarWelcomeScreenSvg,
  arrowUp: arrowUpSvg,
  arrowBottom,
  bell: bellSvg,
  cancelBold: cancelBoldSvg,
  checkboxCheck: checkboxCheckSvg,
  channelSelector: channelSelectorSvg,
  creditCard: creditCardSvg,
  playlistCamera: playlistCameraSvg,
  cardLarge: cardLargeSvg,
  channelPageType: channelIconSvg,
  channels: channelsSvg,
  checkmark: checkmarkSvg,
  chatStar,
  delete: deleteSvg,
  designtabColors: designtabColorsSvg,
  designtabDesktop: designtabDesktopSvg,
  designtabFonts: designtabFontsSvg,
  linkOutline: linkOutlineSvg,
  designtabMobile: designtabMobileSvg,
  designtabText: designtabTextSvg,
  designUpArrow: designUpArrowSvg,
  devices: devicesSVG,
  download: downloadSvg,
  earnings: earningsSvg,
  exclamationCircle: exclamationCircleSvg,
  phone: phoneSVG,
  thickX: closeThickSVG,
  crashPage: crashPageSVG,
  notFoundPage: notFoundPageSVG,
  layoutContentBelow: layoutContentBelowSvg,
  layoutContentInside: layoutContentInsideSvg,
  settingsOutlined: settingsOutlinedSvg,
  settingsV2: settingsOutlinedSvg,
  flowSlider: flowSliderSvg,
  flowStack: flowStackSvg,
  monitor: monitorSVG,
  fortniteEliminations,
  fortnitePlayer,
  fortnitePoints,
  fortnitePosition,
  fortniteRank,
  fortniteRoyals,
  fortniteStar,
  grid: gridSvg,
  groupLarge: groupLargeSvg,
  home: homeSvg,
  landingPageType: landingPageIconSvg,
  newspaper: newspaperSvg,
  cards: cardsSvg,
  chat: chatSvg,
  chatV2: chatV2Svg,
  tips: tipsSVG,
  tipsV2: tipsV2SVG,
  tipsEyeShow: tipsEyeShowSVG,
  tipsLogDollar: tipsLogDollarSVG,
  tipsEyeHide: tipsEyeHideSVG,
  'cancel-thin': cancelThinSvg,
  'close-thin': closeThinSvg,
  cancelShort: cancelShortSvg,
  colorFill: colorFillSvg,
  colorPicker: colorPickerSvg,
  heart: heartSvg,
  helpIcon: helpIconSvg,
  hour: hourSvg,
  pencil: pencilSvg,
  gamer: gamerSvg,
  link: linkSvg,
  pictures: picturesSvg,
  'bar-chart': barChartSvg,
  basket: basketSvg,
  multicamera: multicameraSvg,
  check: checkSvg,
  'list-bullet': listBulletSvg,
  help: helpSvg,
  award: awardSvg,
  awardColored: awardSvg,
  ticket: ticketSvg,
  calendar: calendarSvg,
  twitch: twitchSvg,
  twitter: twitterSvg,
  twitterV2: twitterSvg,
  videocam: videocamSvg,
  menu: menuSvg,
  mute: muteSVG,
  multipleChoiceLarge: multipleChoiceLargeSvg,
  multipleChoiceV2: multipleChoiceV2Svg,
  overlayLarge: overlayLargeSvg,
  opacity: opacitySvg,
  pageLarge: pageLargeSvg,
  panelLarge: panelLargeSvg,
  panels: panelsSvg,
  panelsProfPicEllipse: panelsProfPicEllipseSvg,
  personLarge: personLargeSvg,
  playTriangle: playTriangleSvg,
  playLarge: playLargeSvg,
  playlistLarge: playlistLargeSvg,
  pointer,
  portal: portalSvg,
  productLarge: productLargeSvg,
  pubgDbno: pubgDbnoSvg,
  pubgDmg: pubgDmgSvg,
  questLarge: questLargeSvg,
  ticketsIcon: ticketsIconSvg,
  retweet: retweetSvg,
  resend: resendSvg,
  thumbtack: thumbtackSvg,
  analyticsUpArrow: analyticsUpArrowSvg,
  analyticsDownArrow: analyticsDownArrowSvg,
  shopify: shopifyBackSvg,
  shopifyV2: shopifyBackSvg,
  shoppingCart: shoppingCartSvg,
  successCheckmark: successCheckmarkSvg,
  subscriptionsIcon: subscriptionsIconSvg,
  viewershipEye: analyticsViewershipEyeSvg,
  sadShoppingCart: sadShoppingCartSvg,
  translatedByGoogle: translatedByGoogleSvg,
  user: userSvg,
  userX: userXSvg,
  userPlus: userPlusSvg,
  singleUser: singleUserSvg,
  videoLarge: videoLargeSvg,
  key: keySvg,
  analyticsChartArrow: analyticsChartArrowSvg,
  magnet: magnetSvg,
  engagedAudience: engagedAudienceSVG,
  totalAudience: totalAudienceSVG,
  topLineMetrics: topLineMetricsSVG,
  AnalyticsChartArrow: AnalyticsChartArrowSvg,
  analyticsInfo: analyticsInfoSvg,
  analyticsClock: analyticsClockSvg,
  analyticsEye: analyticsEyeSvg,
  analyticsMoney: analyticsMoneySvg,
  analyticsPerson: analyticsPersonSvg,
  analyticsPersonOutlined: analyticsPersonOutlinedSvg,
  analyticsReturn: analyticsReturnSvg,
  analyticsClockDot: analyticsClockDotSvg,
  analyticsClockPlus: analyticsClockPlusSvg,
  analyticsForward: analyticsForwardSvg,
  analyticsPersonBack: analyticsPersonBackSvg,
  videoPlay: videoPlaySVG,
  warning: warningSvg,
  Chatv2: chatv2Svg,
  Triviav2: triviaSvg,
  Highlightv2: highlightSvg,
  LowerThirdv2: lowerThirdv2Svg,
  PollResultv2: pollResultSvg,
  Pollv2: pollSvg,
  Rafflev2: raffleSvg,
  Tweetv2: tweetSvg,
  Personv2: personSvg,
  Questv2: QuestSvgv2,
  questV3: QuestV3Svg,
  QandAv2: qandaSvg,
  Imagev2: imageSvg,
  imageV3: imageV3Svg,
  Schedulev2: scheduleSvg,
  scheduleV3: scheduleV3Svg,
  ChannelSelectv2: channelSelectSvg,
  channelSelectV3: channelSelectV3Svg,
  channelSelectV3Outline: channelSelectV3OutlineSvg,
  Playlistv2: PlaylistSvgv2,
  playlistV3 :PlaylistSvgV3,
  Twitchv2: twitchv2Svg,
  twitchV3: twitchv3Svg,
  checkv2: checkv2Svg,
  Iframev2: iframeSvg,
  iframeV3: iframeV3Svg,
  Leaderboardv2: leaderboardSvg,
  leaderboardV3: leaderboardV3Svg,
  Donationsv2: donationsSvg,
  fullscreen: fullscreenSvg,
  exitFullscreen: exitFullscreenSvg,
  hideSidebar: hideSidebarSvg,
  showSidebar: showSidebarSvg,
  theater: theaterSvg,
  volumeHigh: volumeHighSvg,
  volumeLow: volumeLowSvg,
  volumeMuted: volumeMutedSvg,
  twitterFilled: twitterFilledSvg,
  uploadPicture: uploadPictureSvg,
  emailConfirmed,
  library: librarySvg,
  librarySave: librarySaveSvg,
  catalog: catalogSvg,
  'plus-thin': plusThinSvg,
  'plus-circle': plusCircleSvg,
  leftArrowGrad: leftArrowGradSvg,
  rightArrowGrad: rightArrowGradSvg,
  upArrowGrad: upArrowGradSvg,
  downArrowGrad: downArrowGradSvg,
  downArrow: downArrowSvg,
  trash: trashSvg,
  trashV2: trashV2Svg,
  eyeFilled: eyeFilledSvg,
  correctCircle: correctCircleSvg,
  wrongCircle: wrongCircleSvg,
  cursorHand: cursorHandSvg,
  eyeHidden: eyeHiddenSvg,
  megaphone: megaphoneSvg,
  'facebook-squared': facebookSquaredSvg,
  mail: mailSvg,
  accessCode: accessCodeSvg,
  accessCodeImgPlaceholder: accessCodeImgPlaceholderSvg,
  changeBackground:changeBackgroundSvg,
  lock: lockSvg,
  shopifyColored: shopifyColoredSvg,
  fbCircleColored: fbCircleColoredSvg,
  gAnalyticsColored: gAnalyticsColoredSvg,
  lowerThird: lowerThirdSvg,
  personPullingString: personPullingStringSvg,
  pollResults: pollResultsSvg,
  channelMock: channelMockSvg,
  landingMock: landingMockSvg,
  maestroBlank: maestroBlankSvg,
  cookie: cookieSvg,
  dash: dashSvg,
  addImage: addImageSvg,
  addVideo: addVideoSvg,
  searchLibrary: searchLibrarySvg,
  cleanDownGradArrow: cleanDownGradArrowSvg,
  plusCircle: plusCircleSvg,
  location: locationSvg,
  clock: clockSvg,
  pause: pauseSvg,
  rectOutline: rectOutlineSvg,
  rectSolid: rectSolidSvg,
  airplay: airplaySvg,
  airplayDisconnect: airplayDisconnectSvg,
  share: shareSvg,
  twitterCircleColored: twitterCircleColoredSvg,
  twitterCircle: twitterCircleSvg,
  chromecast: chromecastSvg,
  chromecastDisconnect: chromecastDisconnectSvg,
  copy: copySvg,
  checkmarkCircle: checkmarkCircleSvg,
  screen: screenSvg,
  circledCheckmark: circledCheckmarkSvg,
  circledCheckmarkUnfilled: circledCheckmarkUnfilledSvg,
  info: infoSvg,
  chartIcon: chartIconSvg,
  merch: merchSvg,
  inMaintenance,
  studioLogo: studioLogoSvg,
  arrowGradLeftCropped: arrowGradLeftCroppedSvg,
  homeFilled: homeFilledSvg,
  imgUpload: imgUploadSvg,
  nav2Channels: nav2ChannelsSvg,
  nav2Library: nav2LibrarySvg,
  nav2Analytics: nav2AnalyticsSvg,
  nav2Community: nav2CommunitySvg,
  nav2Subs: nav2SubsSvg,
  nav2Settings: nav2SettingsSvg,
  nav2Wizard: nav2WizardSvg,
  nav2Help: nav2HelpSvg,
  nav2ThirdParty: nav2ThirdPartySvg,
  nav2SiteAds: nav2SiteAdsSvg,
  nav2Admins: nav2AdminsSvg,
  nav2Billing: nav2BillingSvg,
  nav2Developer: nav2DeveloperSvg,
  nav2Domain: nav2DomainSvg,
  nav2Icons: nav2IconsSvg,
  nav2Localization: nav2LocalizationSvg,
  nav2Login: nav2LoginSvg,
  nav2Receipts: nav2ReceiptsSvg,
  nav2SuperAdmin: nav2SuperAdminSvg,
  nav2UserProfile: nav2UserProfileSvg,
  nav2Earnings: nav2EarningsSvg,
  nav2Engagement: nav2EngagementSvg,
  nav2Insights: nav2InsightsSvg,
  nav2Retention: nav2RetentionSvg,
  nav2Trends: nav2TrendsSvg,
  nav2Videos: nav2VideosSvg,
  nav2Content: nav2ContentSvg,
  nav2Editor: nav2EditorSvg,
  nav2Access: nav2AccessSvg,
  nav2Overlays: nav2OverlaysSvg,
  nav2Design: nav2DesignSvg,
  nav2Panels: nav2PanelsSvg,
  nav2Streaming: nav2StreamingSvg,
  nav2AccessStroke: nav2AccessStrokeSvg,
  nav2ContentStroke: nav2ContentStrokeSvg,
  nav2DesignStroke: nav2DesignStrokeSvg,
  nav2SettingsStroke: nav2SettingsStrokeSvg,
  color: colorSvg,
  refresh: refreshSvg,
  plus: plusSvg,
  plusTiny: plusTinySvg,
  union: unionSvg,
  nav2Achievements: nav2AchievementsSvg,
  circledHelp: circledHelpSvg,
  externalLink: externalLinkSvg,
  externalLink2: externalLink2Svg,
  page: pageSvg,
  pageOutline: pageOutlineSvg,
  channelFavorite: channelFavoriteSvg,
  threeDots: threeDotsSvg,
  liveVideo: liveVideoSvg,
  liveShopping: liveShoppingSvg,
  wizardOptionSearch: wizardOptionSearchSvg,
  free: freeSvg,
  desktopFilled: desktopFilledSvg,
  cogV2: cogV2Svg,
  textAlignmentLeft: textAlignmentLeftSvg,
  textAlignmentCenter: textAlignmentCenterSvg,
  textAlignmentRight: textAlignmentRightSvg,
  layoutLeft: layoutLeftSvg,
  layoutStacked: layoutStackedSvg,
  layoutRight: layoutRightSvg,
  layoutWithVideoLeft: layoutWithVideoLeftSvg,
  layoutWithVideoStacked: layoutWithVideoStackedSvg,
  layoutWithVideoRight: layoutWithVideoRightSvg,
  shoppingBag: shoppingBagSvg,
  stripeLogo: stripeLogoSvg,
  dragAndDropVideo: dragAndDropVideoSvg,
  dragAndDropVideoError: dragAndDropVideoErrorSvg,
  transparentCircled: transparentCircledSvg,
  people: peopleSvg,
  arrowDropDownUp: arrowDropDownUpSvg,
  arrowDropDownDown: arrowDropDownDownSvg,
  uploadFile: uploadFileSvg,
  amazonIcon: amazonSvg,
  removeIconOutline: removeIconOutlineSvg,
  questionMarkFilled: questionMarkFilledSvg,
  mobileHomeOutline: mobileHomeOutlineSvg,
  mobileSearch: mobileSearchSvg,
  mobileProfileFilled: mobileProfileFilledSvg,
  alignLeft,
  alignCenter,
  alignRight,
  fullWidth,
  addTableNode: addTableNodeSvg,
};

const getColorFromProps = (props: IStyledProps & { color?: string }) => props.color || ACCENT_PRIMARY(props);
const getBackgroundColorFromProps = (props: IStyledProps & { backgroundColor?: string }) => props.backgroundColor || SURFACE_3(props);
const getWidthFromProps = (props: IStyledProps & { width?: string }) => props.width || '30px';
const getHeightFromProps = (props: IStyledProps & { width?: string }) => props.width || '30px';
// TODO: ULTRA MAJOR HACKS. YOU HAVE BEEN WARNED. I HATE SVG. IT IS THE WORST. - ANDY
// Need to define a fixed height and width below in order to have Safari render the icons apparently
const STYLE_MAP: {
  [key: string]: FlattenInterpolation<ThemedStyledProps<{ color?: string }, DefaultTheme>>;
} = {
  defaultChannel: css`
    & > svg {
      width: 500px;
      & > .surface2 {
        fill: ${SURFACE_2};
      }
      & > .surface3 {
        fill: ${SURFACE_3};
      }
    }
  `,
  modalHeroBanner: PAGE_BLOCKS_STYLE,
  modalEventCalendar: PAGE_BLOCKS_STYLE,
  modalEmbedCode: PAGE_BLOCKS_STYLE,
  modalFortniteLeaderboard: PAGE_BLOCKS_STYLE,
  modalRichText: PAGE_BLOCKS_STYLE,
  modalShopifyBlock: PAGE_BLOCKS_STYLE,
  modalVideoSpotlight: PAGE_BLOCKS_STYLE,
  modalChannelNavigator: PAGE_BLOCKS_STYLE,
  modalPlaylist: PAGE_BLOCKS_STYLE,
  modalTaggedVideo: PAGE_BLOCKS_STYLE,
  modalImageGallery: PAGE_BLOCKS_STYLE,
  modalTextBanner: PAGE_BLOCKS_STYLE,
  modalFAQ: PAGE_BLOCKS_STYLE,
  modalImageAndText: PAGE_BLOCKS_STYLE,
  pencilSquare: css`
    & > svg {
      width: 16px;
      height: 16px;
    }
  `,
  addToCalGoogle: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 100px;
      height: 50px;
    }
  `,
  addToCalICal: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 100px;
      height: 50px;
    }
  `,
  addToCalYahoo: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 100px;
      height: 50px;
    }
  `,
  adminbar3rdParty: css`
    & > svg {
      & path {
        fill: ${getColorFromProps};
        stroke: transparent !important;
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarAchievements: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarAdmins: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarBilling: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarChannels: css`
    & > svg {
      & > path {
        stroke: ${getColorFromProps};
        fill: transparent !important;
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarCodeInjection: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarColors: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarCommunity: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarConcurrents: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarCustomerProfile: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarDesign: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarDesktop: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarDeveloper: css`
  & > svg {
    & path {
      stroke: transparent !important;
      fill: ${getColorFromProps};
    }
    width: 19px;
    height: 19px;
  }
`,
  adminbarEditor: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarEngagement: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarEntitlements: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarFonts: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarGt: css`
    & svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      fill: ${getColorFromProps};
      width: 13px;
      height: 13px;
    }
  `,
  adminbarHelp: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 20.1px;
      height: 20.1px;
    }
  `,
  adminbarIcons: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarInsights: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarLink: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarLocalization: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarLogIn: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarLt: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 8px;
      height: 8px;
    }
  `,
  navArrow: css`
  & > svg {
    & path {
      stroke: transparent !important;
      fill: ${getColorFromProps};
    }
    width: 7px;
    height: 13px;
  }
`,
  adminbarMobile: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarOverlays: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarPanels: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarRetention: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarEarnings: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarStreaming: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarSuperAdmin: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarText: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarTrends: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarUserProfiles: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarVideoPlayer: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarVideos: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  adminbarWelcomeScreen: css`
    & > svg {
      & path {
        stroke: transparent !important;
        fill: ${getColorFromProps};
      }
      width: 19px;
      height: 19px;
    }
  `,
  arrowUp: css`
    & > svg {
      fill: ${getColorFromProps};
      width: ${getWidthFromProps};
      height: ${getHeightFromProps};
    }
  `,
  arrowBottom: css`
    & > svg {
      fill: ${getColorFromProps};
      width: ${getWidthFromProps};
      height: ${getHeightFromProps};
    }
  `,
  arrowLeft: css`
    & svg {
      width: 8px;
      height: 16px;
      & path {
        fill: ${getColorFromProps};
      }
    }
  `,
  channelSelectArrowLeft: css`
  & svg {
    width: 13.21px;
    height: 25px;
    & path {
      fill: ${getColorFromProps};
    }
  }
`,
  arrowRight: css`
    & svg {
      width: 8px;
      height: 16px;
      & path {
        fill: ${getColorFromProps};
      }
    }
  `,
  channelSelectArrowRight: css`
    & svg {
      width: 13.21px;
      height: 25px;
      & path {
        fill: ${getColorFromProps};
      }
    }
  `,
  arrowUpUnfilled: css`
    & > svg {
      fill: ${getColorFromProps};
      width: ${getWidthFromProps};
      height: ${getHeightFromProps};
    }
  `,
  bell: css`
  & > svg {
    width: 31px;
    height: 31px;
    & * {
      stroke: ${getColorFromProps};
    }
  }
`,
  magnet: css`
    & > svg {
      width: 17px;
      height: 13px;
      & > path {
        stroke: ${getColorFromProps};
      }
    }
  `,
  engagedAudience: css`
   & > svg {
     width: 35px;
     height: 25px;
   }
 `,
  totalAudience: css`
  & > svg {
    width: 35px;
    height: 25px;
  }
`,
  cancelBold: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 15px;
      height: 18px;
    }
  `,
  checkboxCheck: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 100%;
      height: 100%;
    }
  `,
  creditCard: css`
    line-height: 0;
    & > svg {
      fill: ${getColorFromProps};
      width: 100%;
      height: 100%;
    }
  `,
  cardLarge: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 30px;
      height: 30px;
    }
  `,
  channelPageType: css`
    & > svg {
      fill: white;
      opacity: 0.2;
      height: 62px;
      width: 103px;
    }
  `,
  channels: css`
    & > svg {
      width: 31px;
      height: 31px;
      & * {
        stroke: ${getColorFromProps};
      }
    }
  `,
  channelSelector: css`
    & > svg {
      margin: -50px;
      width: 60px;
      height: 60px;
      & path {
        fill: ${getColorFromProps};
      }
    }
  `,
  checkmark: css`
    & > svg {
      width: 20px;
      height: 20px;
      & * {
        fill: ${getColorFromProps};
        stroke: ${getColorFromProps};
      }
    }
  `,
  copyIcon: css`
    & > svg {
      width: 20px;
      height: 20px;
      & * {
        fill: ${getColorFromProps};
        stroke: transparent !important;
      }
    }
  `,
  chatStar: css`
    & > svg {
      fill: ${MAESTRO_BLACK};
      width: 6px;
      height: 6px;
    }
  `,
  designtabColors: css`
    & > svg {
      line-height: 0;
      width: 12px;
      height: 12px;
      & * {
        fill: ${getColorFromProps};
      }
    }
  `,
  designtabDesktop: css`
    & > svg {
      line-height: 0;
      width: 15px;
      height: 15px;
      & * {
        stroke: ${getColorFromProps};
      }
    }
  `,
  designtabFonts: css`
    & > svg {
      line-height: 0;
      width: 16px;
      height: 16px;
      & * {
        fill: ${getColorFromProps};
      }
    }
  `,
  designtabMobile: css`
    & > svg {
      line-height: 0;
      width: 15px;
      height: 15px;
      & * {
        stroke: ${getColorFromProps};
      }
    }
  `,
  designtabText: css`
    & > svg {
      line-height: 0;
      width: 12px;
      height: 12px;
      & > path {
        fill: ${getColorFromProps};
      }
    }
  `,
  designUpArrow: css`
    & > svg {
      line-height: 0;
      width: 9px;
      height: 9px;
      & * {
        fill: ${getColorFromProps};
      }
    }
  `,
  download: css`
    & > svg {
      width: 16px;
      height: 17px;
      & * {
        fill: ${getColorFromProps};
      }
    }
  `,
  exclamationCircle: css`
  & > svg {
    width: 26px;
    height: 26px;
    & > path {
      fill: ${getColorFromProps};
    }
  }
`,
  fortniteEliminations: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 13px;
      height: 13px;
    }
  `,
  fortnitePlayer: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 10px;
      height: 10px;
    }
  `,
  fortnitePoints: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 12px;
      height: 12px;
    }
  `,
  fortniteRank: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 14px;
      height: 14px;
    }
  `,
  fortniteRoyals: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 9px;
      height: 10px;
    }
  `,
  fortniteStar: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 20px;
      height: 20px;
    }
  `,
  grid: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 20px;
      height: 20px;
    }
  `,
  groupLarge: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 30px;
      height: 30px;
    }
  `,
  home: css`
    & > svg {
      width: 31px;
      height: 31px;
      & * {
        fill: ${getColorFromProps};
      }
    }
  `,
  landingPageType: css`
    & > svg {
      fill: white;
      opacity: 0.2;
      width: 103px;
      height: 62px;
    }
  `,
  mail: css`
    line-height: 0;
    & > svg {
      width: 9px;
      height: 9px;
      & * {
        fill: ${getColorFromProps};
      }
    }
  `,
  menu: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 20px;
      height: 20px;
    }
`,
  mute: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 20px;
      height: 20px;
    }
`,
  delete: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 9px;
      height: 9px;
    }
`,
  phone: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 20px;
      height: 40px;
    }
`,
  monitor: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 33px;
      height: 25px;
    }
`,
  playTriangle: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 20px;
      height: 20px;
    }
`,
  thickX: css`
    & > svg {
      fill: ${getColorFromProps};
    }
`,
  newspaper: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_SVG_SIZE}
    }
  `,
  cards: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      width: 24px;
      height: 24px;
    }
  `,
  chat: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_SVG_SIZE}
    }
  `,
  chatV2: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_V2_SVG_SIZE}
    }
 `,
  people: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_V2_SVG_SIZE}
    }
  `,
  tips: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_SVG_SIZE}
    }
 `,
  tipsV2: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_V2_SVG_SIZE}
    }
  `,
  tipsEyeShow: css`
   & > svg, & > svg path {
     fill: ${DEFAULT_FILL_WHITE};
     ${PANEL_SVG_SIZE}
   }
 `,
  tipsLogDollar: css`
   & > svg, & > svg path {
     fill: ${DEFAULT_FILL_WHITE};
     ${PANEL_SVG_SIZE}
   }
 `,
  tipsEyeHide: css`
   & > svg, & > svg path {
     fill: ${DEFAULT_FILL_WHITE};
     ${PANEL_SVG_SIZE}
   }
 `,
  'cancel-thin': css`
    & svg {
      width: 9px;
      height: 9px;
      & path {
        fill: ${DEFAULT_FILL_WHITE};
      }
    }
  `,
  cancelShort: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      width: 10px;
      height: 9px;
    }
  `,
  eyeFilled: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      width: 17px;
      height: 17px;
    }
  `,
  correctCircle: css`
  & > svg {
    width: 16px;
    height: 16px;
  }
  `,
  wrongCircle: css`
  & > svg {
    width: 16px;
    height: 16px;
  }
  `,
  cursorHand: css`
  & > svg, & > svg rect {
    width: 40px;
    height: 45px;
  }
  `,
  eyeHidden: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      width: 10px;
      height: 9px;
    }
  `,
  megaphone: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_SVG_SIZE}
    }
  `,
  heart: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_SVG_SIZE}
    }
  `,
  pencil: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      width: 11.5px;
      height: 11.5px;
    }
  `,
  gamer: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_SVG_SIZE}
    }
  `,
  link: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_SVG_SIZE}
    }
  `,
  pictures: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_SVG_SIZE}
    }
  `,
  'bar-chart': css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_SVG_SIZE}
    }
  `,
  basket: css`
    line-height: 0;
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      width: 21px;
      height: 21px;
    }
  `,
  multicamera: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_SVG_SIZE}
    }
  `,
  check: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_SVG_SIZE}
    }
  `,
  panels: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      width: 24px;
      height: 24px;
    }
  `,
  'list-bullet': css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_SVG_SIZE}
    }
  `,
  help: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_SVG_SIZE}
    }
  `,
  award: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_SVG_SIZE}
    }
  `,
  awardColored: css`
  & > svg, & > svg path {
    fill: ${getColorFromProps};
  }
`,
  ticket: css`
    & > svg, & > svg path {
      ${({ color = 'white' }: { color?: string }) => `
        fill: ${color};
        stroke: ${color};
      `};
      ${PANEL_SVG_SIZE}
    }
  `,
  calendar: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_SVG_SIZE}
    }
  `,
  twitch: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_SVG_SIZE}
    }
  `,
  twitter: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_SVG_SIZE}
    }
  `,
  twitterV2: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_V2_SVG_SIZE}
    }
  `,
  videocam: css`
    line-height: 0;
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      width: 22px;
      height: 22px;
    }
  `,
  multipleChoiceLarge: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 30px;
      height: 30px;
    }
  `,
  overlayLarge: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 30px;
      height: 30px;
    }
  `,
  pageLarge: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 30px;
      height: 30px;
    }
  `,
  panelLarge: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 30px;
      height: 30px;
    }
  `,
  panelsProfPicEllipse: css`
    & > svg {
      width: 47px;
      height: 46px;
      & stop {
        stop-color: ${getColorFromProps};
      }
    }
  `,
  library: css`
    line-height: 0;
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      width: 22px;
      height: 22px;
    }
  `,
  librarySave: css`
    line-height: 0;
    & > svg, & > svg path {
      fill: ${({ color = 'black' }: { color?: string }) => color};
      width: 22px;
      height: 22px;
    }
  `,
  catalog: css`
    line-height: 0;
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      width: 22px;
      height: 22px;
    }
  `,
  'plus-thin': css`
    line-height: 0;
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      width: 9px;
      height: 9px;
    }
  `,
  'plus-circle': css`
    line-height: 0;
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      width: 16px;
      height: 16px;
    }
  `,
  'facebook-squared': css`
    line-height: 0;
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_SVG_SIZE}
    }
  `,
  leftArrowGrad: css`
    line-height: 0;
    & > svg {
      width: 50px;
      height: 50px;
      & > g > path {
        fill: ${getColorFromProps};
      }
    }
  `,
  rightArrowGrad: css`
    line-height: 0;
    & > svg {
      width: 50px;
      height: 50px;
      & > g > path {
        fill: ${getColorFromProps};
      }
    }
  `,
  upArrowGrad: css`
    line-height: 0;
    & > svg {
      width: 50px;
      height: 50px;
      & > g > path {
        fill: ${getColorFromProps};
      }
    }
  `,
  downArrowGrad: css`
    line-height: 0;
    & > svg {
      width: 50px;
      height: 50px;
      & > g > path {
        fill: ${getColorFromProps};
      }
    }
  `,
  downArrow: css``,
  topLineMetrics: css`
    & > svg {
      width: 13px;
      height: 13px;
      margin-right: 5px;
      & path {
        fill: ${getColorFromProps};
      }
    }
 `,
  key: css`
  & > svg {
    fill: ${getColorFromProps};
    width: 16px;
    height: 16px;
  }
 `,
  viewershipEye: css`
  & > svg {
    width: 17px;
    height: 13px;
  }
  `,
  personLarge: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 30px;
      height: 30px;
    }
  `,
  Chatv2: css`
    & > svg {
      width: 20px;
      height: 20px;
    }
  `,
  Triviav2: css`
     & > svg {
       width: 27px;
       height: 20px;
     }
  `,
  Highlightv2: css`
    & > svg {
      width: 20px;
      height: 20px;
    }
  `,
  LowerThirdv2: css`
    & > svg {
      width: 20px;
      height: 20px;
    }
  `,
  PollResultv2: css`
    & > svg {
      width: 20px;
      height: 20px;
    }
  `,
  Pollv2: css`
    & > svg {
      width: 20px;
      height: 20px;
    }
  `,
  Rafflev2: css`
    & > svg {
      width: 20px;
      height: 20px;
    }
  `,
  Tweetv2: css`
    & > svg {
      width: 20px;
      height: 20px;
    }
  `,
  Personv2: css`
    & > svg {
      width: 20px;
      height: 20px;
    }
  `,
  Questv2: css`
    & > svg {
      width: 20px;
      height: 20px;
    }
  `,
  questV3: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_V2_SVG_SIZE}
    }
  `,
  QandAv2: css`
   & > svg {
     width: 20px;
     height: 20px;
   }
 `,
  Imagev2: css`
  & > svg {
    width: 20px;
    height: 20px;
  }
`,
  imageV3: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_V2_SVG_SIZE}
    }
  `,
  Schedulev2: css`
    & > svg {
      width: 20px;
      height: 20px;
    }
  `,
  scheduleV3: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_V2_SVG_SIZE}
    }
  `,
  ChannelSelectv2: css`
    & > svg {
      width: 20px;
      height: 20px;
    }
  `,
  channelSelectV3: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_V2_SVG_SIZE}
    }
  `,
  Playlistv2: css`
    & > svg {
      width: 20px;
      height: 20px;
    }
  `,
  playlistV3: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_V2_SVG_SIZE}
    }
  `,
  Twitchv2: css`
    & > svg {
      width: 20px;
      height: 20px;
    }
  `,
  twitchV3: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_V2_SVG_SIZE}
    }
  `,
  multipleChoiceV2: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_V2_SVG_SIZE}
    }
  `,
  checkv2: css`
    & > svg {
      width: 20px;
      height: 20px;
    }
  `,
  Iframev2: css`
    & > svg {
      width: 20px;
      height: 20px;
    }
  `,
  iframeV3: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_V2_SVG_SIZE}
    }
  `,
  Leaderboardv2: css`
    & > svg {
      width: 20px;
      height: 20px;
    }
  `,
  leaderboardV3: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_V2_SVG_SIZE}
    }
  `,
  Donationsv2: css`
    & > svg {
      width: 20px;
      height: 20px;
    }
  `,
  pause: css`
    & > svg {
      width: ${getWidthFromProps};
      height: ${getHeightFromProps};
      & path {
        fill: ${getColorFromProps};
      }
    }
  `,
  rectOutline: css`
    width: 20px;
    height: 20px;
  `,
  rectSolid: css`
    width: 20px;
    height: 20px;
  `,
  playLarge: css`
    & > svg {
      fill: ${getColorFromProps};
      width: ${getWidthFromProps};
      height: ${getHeightFromProps};
    }
  `,
  playlistCamera: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 30px;
      height: 30px;
    }
  `,
  playlistLarge: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 30px;
      height: 30px;
    }
  `,
  pointer: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 30px;
      height: 30px;
    }
  `,
  personPullingString: css`
    width: 100%;
    & > svg {
      fill: ${getColorFromProps};
      width: 100%;
      height: 100%;
    }
  `,
  portal: css`
    & > svg {
      width: 31px;
      height: 31px;
    }
    & *[stroke] {
      stroke: ${getColorFromProps};
    }
  `,
  productLarge: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 30px;
      height: 30px;
    }
  `,
  pubgDbno: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 30px;
      height: 30px;
    }
  `,
  pubgDmg: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 30px;
      height: 30px;
    }
  `,
  questLarge: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 30px;
      height: 30px;
    }
  `,
  questionLarge: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 30px;
      height: 30px;
    }
  `,
  retweet: css`
    & > svg {
      & > path {
        fill: ${getColorFromProps};
      }
      ${PANEL_SVG_SIZE}
    }
  `,
  thumbtack: css`
    & > svg {
      fill: ${getColorFromProps};
      width: ${getWidthFromProps};
      height: ${getHeightFromProps};
    }
  `,
  analyticsDownArrow: css`
    & > svg {
      fill: ${getColorFromProps};
      width: ${getWidthFromProps};
      height: ${getHeightFromProps};
    }
  `,
  analyticsUpArrow: css`
    & > svg {
      fill: ${getColorFromProps};
      width: ${getWidthFromProps};
      height: ${getHeightFromProps};
    }
  `,
  twitterFilled: css`
    & > svg {
      fill: ${({ color = '#7ADDFC' }: IStyledProps & { color?: string }) => color};
      ${PANEL_SVG_SIZE}
    }
  `,
  trash: css`
    & > svg {
      & > path {
        fill: ${({ color = MAESTRO_WHITE }: IStyledProps & { color?: string }) => color};
      }
      width: 16px;
      height: 16px;
    }
  `,
  trashV2: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_V2_ACTION_SVG_SIZE}
    }
  `,
  shopify: css`
    & > svg {
      fill: ${({ color = MAESTRO_WHITE }: IStyledProps & { color?: string }) => color};
      width: ${getWidthFromProps};
      height: ${getHeightFromProps};
    }
  `,
  shopifyV2: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_V2_SVG_SIZE}
    }
  `,
  settingsV2: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_V2_ACTION_SVG_SIZE}
    }
  `,
  warning: css`
    & > svg {
      width: 27px;
      height: 22px;
      & > path {
        fill: ${getColorFromProps};
      }
    }
  `,
  circledCheckmark: css`
  & > svg {
    width: 12px;
    height: 12px;
    & > path {
      fill: ${getBackgroundColorFromProps};
    }
    & > circle {
      fill: ${getColorFromProps};
    }
  }
  `,
  circledCheckmarkUnfilled: css`
    & > svg, & svg > path {
      fill: ${getColorFromProps};
      width: ${getWidthFromProps};
      height: ${getHeightFromProps};
    }
  `,
  shoppingCart: css`
  & > svg, & svg > path {
    fill: ${getColorFromProps};
    width: ${getWidthFromProps};
    height: ${getHeightFromProps};
  }
`,
  successCheckmark: css`
    & > svg {
      width: 6px;
      height: 6px;
      & * {
        fill: ${getColorFromProps};
      }
    }
  `,
  sadShoppingCart: css`
  & > svg {
    & > path:first-child {
      fill: ${MAESTRO_WHITE};
      ${(props) => {
        const color: any = getBackgroundColorFromProps(props);
        let base = 0.3;
        if (lightOrDarkColor(color) === 'light') {
          base = -0.3;
        }
        return `fill: ${shadeHexColor(color, base)};`;
      }}
    }
    & > path {
      fill: ${getColorFromProps};
    }
    width: ${getWidthFromProps};
    height: ${getHeightFromProps};
  }
  `,
  thumbtackUnfilled: css`
    & > svg {
      fill: ${getColorFromProps};
      width: ${getWidthFromProps};
      height: ${getHeightFromProps};
    }
  `,
  translatedByGoogle: css`
    & > svg {
      fill: ${getColorFromProps};
      height: 16px;
    }
  `,
  uploadPicture: css`
    & > svg {
      width: 15px;
      height: 15px;
      line-height: 0;
      & * {
        fill: ${getColorFromProps};
      }
    }
  `,
  videoLarge: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 30px;
      height: 30px;
    }
  `,
  videoPlay: css`
    & > svg {
      width: 40px;
      height: 27px;
    }
  `,
  fullscreen: css`
    & svg {
      width: 19px;
      height: 13px;
      & path {
        fill: ${getColorFromProps};
      }
    }
  `,
  exitFullscreen: css`
    & svg {
      width: 19px;
      height: 13px;
      & path {
        fill: ${getColorFromProps};
      }
    }
  `,
  emailConfirmed: css`
    & > svg  {
      & > circle {
        fill: ${getColorFromProps};
      }
      width: 70px;
      height: 70px;
    }
  `,
  accessCode: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 19px;
      height: 19px;
    }
  `,
  lock: css`
    & > svg {
      width: 19px;
      height: 19px;
      & > path {
        fill: ${getColorFromProps};
      }
    }
  `,
  shopifyColored: css`
    & > svg {
      width: 19px;
      height: 19px;
    }
  `,
  fbCircleColored: css`
    & > svg {
      width: 19px;
      height: 19px;
    }
  `,
  twitterCircleColored: css`
    & > svg {
      width: 19px;
      height: 19px;
    }
  `,
  twitterCircle: css`
    & > svg {
      width: 19px;
      height: 19px;
      & path {
        fill: ${getColorFromProps};
      }
    }
  `,
  gAnalyticsColored: css`
    & > svg {
      width: 19px;
      height: 19px;
    }
  `,
  pollResults: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 19px;
      height: 19px;
    }
  `,
  lowerThird: css`
    & > svg {
      width: 19px;
      height: 19px;
      & > path {
        fill: ${getColorFromProps};
      }
    }
  `,
  hideSidebar: css`
    & > svg {
      width: 19px;
      height: 13px;
      & > path {
        &:not(:last-child) {
          fill: ${getColorFromProps};
          stroke: transparent !important;
        }
        &:last-child {
          fill: transparent !important;
          stroke: ${getColorFromProps};
        }
      }
    }
  `,
  showSidebar: css`
    & > svg {
      width: 19px;
      height: 13px;
      & > path {
        fill: ${getColorFromProps};
        &:last-child {
          fill: transparent;
          stroke: ${getColorFromProps};
        }
      }
    }
  `,
  channelMock: CHANNEL_OR_PAGE_CREATION_ICON_SVG,
  landingMock: CHANNEL_OR_PAGE_CREATION_ICON_SVG,
  maestroBlank: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 15px;
      height: 15px;
    }
  `,
  cookie: css`
    & > svg {
      width: 20px;
      height: 20px;
      & > path {
        fill: ${getColorFromProps};
      }
    }
  `,
  user: css`
    & > svg {
      width: 20px;
      height: 20px;
      & > path {
        fill: ${getColorFromProps};
      }
    }
  `,
  singleUser: css`
    & > svg {
      width: 17px;
      height: 17px;
      & > path {
        fill: ${getColorFromProps};
      }
    }
  `,
  dash: css`
    & > svg {
      width: 10px;
      height: 5px;
      & > path {
        fill: ${getColorFromProps};
      }
    }
  `,
  theater: css`
    & > svg {
      width: 19px;
      height: 13px;
      & > path {
        fill: ${getColorFromProps};
      }
    }
  `,
  addImage: css`
    & > svg {
      width: 18px;
      height: 18px;
      & > path {
        fill: ${getColorFromProps};
      }
    }
  `,
  addVideo: css`
    & > svg {
      width: 24px;
      height: 24px;
      & > path {
        fill: ${getColorFromProps};
      }
    }
  `,
  analyticsEye: css`
    & > svg {
      height: 20px;
      width: 20px !important;
    }
  `,
  analyticsClock: css`
    & > svg {
      height: 21.3px;
      width: 21.3px !important;
    }
  `,
  analyticsInfo: css`
    & > svg {
      height: 13px;
      width: 13px !important;
      fill: ${getColorFromProps};
    }
  `,
  searchLibrary: css`
    & > svg {
      width: 18px;
      height: 18px;
      & > path {
        fill: ${getColorFromProps};
      }
    }
  `,
  cleanDownGradArrow: css`
    & > svg {
      width: 17px;
      height: 10px;
      & stop {
        stop-color: ${getColorFromProps};
      }
      & path {
        fill: ${getColorFromProps};
      }
    }
  `,
  plusCircle: css`
    line-height: 0;
    & svg {
      width: 16px;
      height: 16px;
      & path {
        fill: ${getColorFromProps};
      }
    }
  `,
  location: css`
    line-height: 0;
    & svg {
      width: 14px;
      height: 18px;
      & path {
        fill: ${getColorFromProps};
      }
    }
  `,
  clock: css`
    line-height: 0;
    & svg {
      width: 17px;
      height: 17px;
      & path {
        fill: ${getColorFromProps};
      }
    }
  `,
  airplay: css`
    & > svg {
      width: 19px;
      height: 13px;
    }
  `,
  info: css`
    & > svg {
      width: 15px;
      height: 15px;
      & > path {
        fill: ${getColorFromProps};
      }
    }
  `,
  airplayDisconnect: css`
    & > svg {
      width: 19px;
      height: 13px;
      & > path {
        fill: ${getColorFromProps};
      }
    }
  `,
  chromecast: css`
    & svg {
      width: 19px;
      height: 13px;
      & path {
        fill: ${getColorFromProps};
      }
    }
  `,
  chromecastDisconnect: css`
    & svg {
      width: 19px;
      height: 13px;
      & path {
        fill: ${getColorFromProps};
      }
    }
  `,
  share: css`
    & svg {
      width: 13px;
      height: 13px;
      & path {
        fill: ${getColorFromProps};
      }
    }
  `,
  copy: css`
    & svg {
      width: 13px;
      height: 13px;
      & path {
        fill: ${getColorFromProps};
      }
    }
  `,
  checkmarkCircle: css`
    & svg {
      width: 9px;
      height: 9px;
      & path {
        fill: ${getColorFromProps};
      }
    }
  `,
  screen: css`
    & svg {
      width: 198px;
      height: 146px;
      & path {
        fill: ${getColorFromProps};
      }
    }
  `,
  arrowGradLeftCropped: css`
    line-height: 0;
    & > svg {
      width: 13px;
      height: 13px;
      & > g > path {
        fill: ${getColorFromProps};
      }
    }
  `,
  refresh: css`
    & > svg {
      & path {
        fill: ${getColorFromProps};
      }
      width: 15px;
      height: 15px;
    }
    `,
  plus: css`
    width: 40px;
    height: 40px;
    & > svg {
      & path {
        fill: ${getColorFromProps};
      }
    }
  `,
  plusTiny: css`
    & > svg, & > svg path {
      fill: ${DEFAULT_FILL_WHITE};
      ${PANEL_V2_ACTION_SVG_SIZE}
    }
  `,
  homeFilled: css`
    line-height: 0;
    & svg {
      width: 10.3px;
      height: 9.13px;
      & path {
        fill: ${getColorFromProps};
      }
    }
  `,
  imgUpload: css`
    & svg {
      width: 42px;
      height: 42px;
      & path {
        fill: ${getColorFromProps};
      }
    }
  `,
  crashPage: THEME_ICON_SVG,
  notFoundPage: THEME_ICON_SVG,
  nav2Channels: NAV2_ICON_SVG,
  nav2Library: NAV2_ICON_SVG,
  nav2Analytics: NAV2_ICON_SVG,
  nav2Community: css`
    ${NAV2_ICON_SVG}
    & svg path {
      fill: none;
      stroke: ${getColorFromProps};
    }
  `,
  nav2Subs: NAV2_ICON_SVG,
  nav2Settings: NAV2_ICON_SVG,
  nav2Wizard: NAV2_ICON_SVG,
  nav2Help: NAV2_ICON_SVG,
  nav2ThirdParty: NAV2_ICON_SVG,
  nav2SiteAds: NAV2_ICON_SVG,
  nav2Admins: NAV2_ICON_SVG,
  nav2Billing: NAV2_ICON_SVG,
  nav2Developer: NAV2_ICON_SVG,
  nav2Domain: NAV2_ICON_SVG,
  nav2Icons: NAV2_ICON_SVG,
  nav2Localization: NAV2_ICON_SVG,
  nav2Login: NAV2_ICON_SVG,
  nav2Receipts: NAV2_ICON_SVG,
  nav2SuperAdmin: NAV2_ICON_SVG,
  nav2UserProfile: NAV2_ICON_SVG,
  nav2Earnings: NAV2_ICON_SVG,
  nav2Engagement: NAV2_ICON_SVG,
  nav2Insights: NAV2_ICON_SVG,
  nav2Retention: NAV2_ICON_SVG,
  nav2Trends: NAV2_ICON_SVG,
  nav2Videos: NAV2_ICON_SVG,
  nav2Content: NAV2_ICON_SVG,
  nav2Editor: NAV2_ICON_SVG,
  nav2Access: NAV2_ICON_SVG,
  nav2Overlays: NAV2_ICON_SVG,
  nav2Design: NAV2_ICON_SVG,
  nav2Panels: NAV2_ICON_SVG,
  nav2Streaming: NAV2_ICON_SVG,
  nav2AccessStroke: NAV2_ICON_SVG,
  nav2ContentStroke: css`
    ${NAV2_ICON_SVG}
    & svg path {
      fill: none;
      stroke: ${getColorFromProps};
    }
  `,
  nav2DesignStroke: NAV2_ICON_SVG,
  nav2SettingsStroke: NAV2_ICON_SVG,
  nav2Achievements: NAV2_ICON_SVG,
  volumeHigh: VOLUME_ICON_STYLE,
  volumeLow: VOLUME_ICON_STYLE,
  volumeMuted: VOLUME_ICON_STYLE,
  circledHelp: css`
    & svg {
      width: 13px;
      height: 13px;
      & path {
        fill: ${getColorFromProps};
      }
    }
  `,
  inMaintenance: css`
    & > svg {
      fill: ${getColorFromProps};
      width: ${getWidthFromProps};
      height: ${getHeightFromProps};
    }
  `,
  color: css`
    & > svg {
      width: 13.8px;
      height: 18.49px;
    }
  `,
  externalLink: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 16px;
      height: 16px;
    }
  `,
  externalLink2: NAV2_ICON_SVG,
  page: css`
    & > svg {
      fill: ${getColorFromProps};
      width: 19px;
      height: 19px;
    }
  `,
  threeDots: css`
    & > svg {
      width: 20px;
      height: 4px;
    }
  `,
  channelFavorite: css`
    & > svg {
      width: 16px;
      height: 16px;
      & rect {
        fill: ${getColorFromProps};
      }
    }
  `,
  liveVideo: css`
    & svg {
      width: 28px;
      height: 20px;
    }
  `,
  liveShopping: css`
    & svg {
      width: 28px;
      height: 27px;
    }
  `,
  wizardOptionSearch: css`
    & svg {
      width: 27px;
      height: 25px;
    }
  `,
  free: css`
    & svg {
      width: 26px;
      height: 24px;
    }
  `,
  desktopFilled: css`
    & svg {
      width: 19px;
      height: 19px;
    }
  `,
  cogV2: css`
    & svg {
      width: 18px;
      height: 18px;
      & path {
        fill: ${getColorFromProps};
      }
    }
  `,
  textAlignmentLeft: css`
    & svg {
      width: 15.67px;
      height: 9.5px;
    }
  `,
  textAlignmentCenter: css`
    & svg {
      width: 15.67px;
      height: 9.5px;
    }
  `,
  textAlignmentRight: css`
    & svg {
      width: 15.67px;
      height: 9.5px;
    }
  `,
  layoutLeft: css`
    & svg {
      width: 51.1px;
      height: 16.35px;
    }
  `,
  layoutStacked: css`
    & svg {
      width: 24.85px;
      height: 27.69px;
    }
  `,
  layoutRight: css`
    & svg {
      width: 51.68px;
      height: 16.35px;
    }
  `,
  layoutWithVideoLeft: css`
    & svg {
      width: 66px;
      height: 34px;
    }
  `,
  layoutWithVideoRight: css`
    & svg {
      width: 54px;
      height: 34px;
    }
  `,
  layoutWithVideoStacked: css`
    & svg {
      width: 34px;
      height: 34px;
    }
  `,
  shoppingBag: css`
    & svg {
      width: 24px;
      height: 24px;
    }
  `,
  stripeLogo: css`
    & svg {
      width: 38.46px;
      height: 15.96px;
      & path {
        fill: ${getColorFromProps};
      }
    }
  `,
  helpIcon: css`
    & svg {
      & path {
        fill: ${getColorFromProps};
      }
    }
  `,
  uploadFile: css`
    & svg {
      width: 14px;
      height: 14px;
      & path {
        fill: ${getColorFromProps};
      }
    }
  `,
  amazonIcon: css`
    & svg {
      width: 20px;
      height: 20px;
    }
  `,
  removeIconOutline: css`
    & svg {
      width: 24px;
      height: 24px;
      & path {
        fill: ${getColorFromProps};
      }
    }
  `,
  questionMarkFilled: css`
    & svg {
      width: 14px;
      height: 14px;
      & path {
        fill: ${getColorFromProps};
      }
    }
    `,
    mobileSearch: css`
    & svg {
      width: 28px;
      height: 28px;
      & path {
        fill: ${getColorFromProps};
      }
    }
    `,
    mobileHomeOutline: css`
    & svg {
      width: 28px;
      height: 28px;
    }
    `,
    mobileProfileFilled: css`
    & svg {
      width: 28px;
      height: 28px;
      & path {
        fill: ${getColorFromProps};
      }
    }
    `,
    addTableNode: css`
    & svg {
      width: 15px;
      height: 10px;
      & path {
        fill: ${getColorFromProps};
      }
    }
    `,
};

const Container = styled.span<{
  admin: boolean;
  backgroundColor?: string;
  color?: string;
  name: string,
}>`
  display: inline-block;
  & > svg {
    vertical-align: middle;
  }
  ${({ name }) => (hasOwn(STYLE_MAP, name) ? STYLE_MAP[name] : '')};
  ${(props) => props.admin && ACTION_ICON_SVG}
`;

interface IProps {
  admin?: boolean;
  backgroundColor?: string;
  className?: string;
  color?: string;
  'data-testid'?: string;
  keyValue?: string;
  name: string;
  onBlur?: FocusEventHandler;
  onClick?: () => void;
  onFocus?: FocusEventHandler;
  tabIndex?: number;
}

const SvgIcon = forwardRef<HTMLSpanElement, IProps>(({
  admin,
  className,
  color,
  backgroundColor,
  name,
  onBlur,
  onClick,
  onFocus,
  keyValue,
  tabIndex,
  ...props
}, ref) => {
  if (!hasOwn(SVG_ICON_MAP, name)) {
    warning(
      false,
      `Non-existent SVG icon: ${JSON.stringify(name)}`,
    );
    return null;
  }
  return (
    <Container
      admin={admin!}
      className={className}
      color={color}
      data-testid={props['data-testid']}
      backgroundColor={backgroundColor}
      dangerouslySetInnerHTML={{ __html: SVG_ICON_MAP[name] }}
      name={name}
      onBlur={onBlur}
      onClick={onClick}
      onFocus={onFocus}
      key={keyValue}
      tabIndex={tabIndex || 0}
      ref={ref}
    />
  );
});

SvgIcon.defaultProps = {
  admin: false,
  className: '',
};

export default SvgIcon;
