import styled, { StyledProps, css } from 'styled-components';
import NotFound from 'components/ui/NotFound';
import {
  BREAK_WORD,
  mobileOnly,
  THIN_SCROLL_BAR,
  HIDE_SCROLLBAR,
} from 'style/mixins';
import { MAESTRO_BLACK, SPACING_SMALL, SURFACE_1 } from 'style/constants';
import { IAccessGateBackground, GateBackgroundEnum } from 'models/IGate';
import { rgba } from 'colors';

export const renderGateBackground = (
  isMobile: boolean,
  background?: IAccessGateBackground | null,
) => {
  if (!background) {
    return null;
  }

  if ((background.desktopImage || (isMobile && background.mobileImage)) && background.type !== GateBackgroundEnum.color) {
    return null;
  }

  return css`
    background-color: ${rgba(background?.color || MAESTRO_BLACK , background?.opacity || 1)};
  `;
};

export const OuterContainer = styled.div<
  StyledProps<{
    backgroundImage?: boolean;
    gateBackground?: IAccessGateBackground | null;
    isChannelGateOn?: boolean;
    isMobile: boolean;
    transparentBackground?: boolean;
  }>
>`
  position: relative;
  background-color: ${({ transparentBackground, ...props }) =>
    transparentBackground ? 'transparent' : SURFACE_1(props)};
  height: 100%;
  overflow: hidden;
  ${(props) => {
    if (props.isChannelGateOn) {
      return renderGateBackground(props.isMobile, props.gateBackground);
    }
    return '';
  }}
  ${mobileOnly`
    padding: 0;
    position: relative;
    overflow: ${(props) => {
      return props.pageType === 'portal' || props.isChannelGateOn
        ? 'hidden'
        : 'visible';
    }};
    height: ${(props) => {
      return props.pageType === 'portal' || props.isChannelGateOn
        ? '100%'
        : 'fit-content';
    }};
  `}
  ${({ backgroundImage }) => backgroundImage && 'background: transparent;'}
`;

/*
  overflow overlay is new tech that allows scrollbar to appear over
  if it isn't supported auto will take over. this is intentional.
*/

export const InnerContainer = styled.div<{
  backgroundImage?: boolean;
  isChannelGateOn?: boolean;
  isLandingPageType?: boolean;
  isVideoOnlyMode?: boolean;
}>`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  ${HIDE_SCROLLBAR}
  ${mobileOnly`
    ${THIN_SCROLL_BAR}
    ${(props) =>
      !props.isLandingPageType &&
      css`
        aspect-ratio: 16 / 9;
      `}
  `};
  width: 100%;
  > *:not(:first-child) {
    margin-top: ${SPACING_SMALL};
  }
  ${mobileOnly`
    && {
      overflow: visible;
    }
  `};
  ${(props) =>
    props.isVideoOnlyMode
      ? `
    &:focus, &:hover, &:focus {
      overflow: hidden;
    }`
      : ''};

  ${(props) =>
    !props.isChannelGateOn &&
    !props.backgroundImage &&
    css`
      background-color: ${SURFACE_1};
    `};
`;

export const StyledNotFound = styled(NotFound)`
  flex-grow: 1;
`;

export const DebugJson = styled.div`
  ${BREAK_WORD}
`;
