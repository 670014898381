import React, { ReactNode, useState } from 'react';
import styled, { css } from 'styled-components';
import range from 'lodash/range';
import useChunkPagination, { ChunkPaginationReturnType } from 'hooks/use-chunk-pagination';
import { useSelector } from 'react-redux';
import { isMobileLayout } from 'services/device';
import { HIDE_SCROLLBAR } from 'style/mixins';
import ArrowControls from './ArrowControls';

interface PageSliderProps<T> {
  absoluteControls?: boolean;
  children: (pageItems: T[], pageIndex: number, pageCount: number) => ReactNode;
  className?: string;
  controlsChildren?: ReactNode;
  controlsCss?: ReturnType<typeof css> | string;
  isScrollable?: boolean;
  items: T[];
  itemsPerPage?: number;
  renderChildrenWithoutItems?: boolean;
}

enum PageFlow {
  Backwards = 1,
  Still = 0,
  Forward = -1,
}

type ExternalPageSliderProps<T> = (
  Omit<PageSliderProps<T>, 'items' | 'itemsPerPage'> &
  { pagination: ChunkPaginationReturnType<T> }
);

export function ExternalPageSlider<T>({
  absoluteControls,
  children,
  className,
  controlsChildren,
  controlsCss,
  isScrollable = false,
  pagination: {
    canGoBack,
    canGoForward,
    goToNextPage,
    goToPrevPage,
    page,
    pages,
    pageCount,
    pageIndex,
  },
  renderChildrenWithoutItems = false,
}: ExternalPageSliderProps<T>) {
  const [pageFlow, setPageFlow] = useState(PageFlow.Still);
  const isMobile = useSelector(isMobileLayout);

  const updatePage = () => {
    setPageFlow(PageFlow.Still);
    switch (pageFlow) {
      case PageFlow.Backwards: return goToPrevPage();
      case PageFlow.Forward: return goToNextPage();
    }
  };

  const onNext = () => {
    if (canGoForward) {
      setPageFlow(PageFlow.Forward);
    }
  };

  const onPrev = () => {
    if (canGoBack) {
      setPageFlow(PageFlow.Backwards);
    }
  };

  const containerProps = {
    className,
    isScrollable,
  };

  if (isScrollable) {
    return (
      <PageSlider {...containerProps}>
        {children(page, pageIndex, pageCount)}
      </PageSlider>
    );
  }

  return (
    <PageSlider {...containerProps}>
      <ArrowControls
        absolutelyPositioned={absoluteControls}
        activeNext={canGoForward}
        activePrev={canGoBack}
        hideControls={pageCount < 2}
        onNext={onNext}
        onPrev={onPrev}
        controlsCss={controlsCss}
      >
        {controlsChildren}
      </ArrowControls>
      <Slider onTransitionEnd={updatePage} pageFlow={pageFlow}>
        {range(pageIndex - 2, pageIndex + 3).map((currentPageIndex, i) => {
          const hide = !isMobile && currentPageIndex !== pageIndex && pageFlow === PageFlow.Still;
          const pageItems = pages[currentPageIndex];
          return renderChildrenWithoutItems || pageItems ? (
            <PageWrapper key={i} hide={hide}>
              {children(pageItems || [], currentPageIndex, pageCount)}
            </PageWrapper>
          ) : <PageWrapper key={i} hide={hide} />;
        })}
      </Slider>
    </PageSlider>
  );
}

export default function<T>({
  children,
  items,
  itemsPerPage = 1,
  ...props
}: PageSliderProps<T>) {
  return (
    <ExternalPageSlider
      {...props}
      pagination={useChunkPagination(items, itemsPerPage)}
    >
      {children}
    </ExternalPageSlider>
  );
}

const PageSlider = styled.div<{ isScrollable: boolean; }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  ${({ isScrollable }) => isScrollable && css`
    ${HIDE_SCROLLBAR}
    overflow: auto hidden;
  `}
  z-index: 1;
`;

const Slider = styled.div<{pageFlow: PageFlow}>`
  display: flex;
  flex-wrap: nowrap;
  ${({ pageFlow }) => css`
    ${pageFlow !== PageFlow.Still && 'transition: transform 0.25s;'}
    transform: translateX(${100 * pageFlow}%);
  `}
`;

const PageWrapper = styled.div<{hide: boolean}>`
  min-width: 100%;
  width: 100%;
  height: 100%;
  transform: translateX(-200%);
  opacity: ${({ hide }) => hide ? 0 : 1};
`;
