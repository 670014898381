import React, { useContext, useEffect, useState, ReactText } from 'react';
import hash from 'json-stable-stringify';
import { Player } from '@lottiefiles/react-lottie-player';
import { ThemeContext } from 'styled-components';
import Color from 'color';

import { hexToRgbaDecimal } from 'utils';

import SpinnerAnimation from './Spinner.json';

export class ThemedSpinner {
  private _Animation: typeof SpinnerAnimation;

  constructor(fillColor: string) {
    const hex =  Color(fillColor).hex().replace('#', '');
    const rgbaDecimal = hexToRgbaDecimal(hex);

    if (!rgbaDecimal) throw new Error(`Bad fill color ${fillColor}`);

    this._Animation = {
      ...SpinnerAnimation,
    };

    if (this._Animation?.assets[0]?.layers?.length) {
      this._Animation.assets[0].layers.forEach((layer: Record<string, any>) => {
        if (layer?.shapes[0]?.it[2]?.c?.k) {
          layer.shapes[0].it[2].c.k = rgbaDecimal;
        }
      });
    }
  }

  get Animation () {
    return this._Animation;
  }
}

const Spinner = ({ style }: { style?: { [key: string]: ReactText } }) => {
  const themeContext = useContext(ThemeContext);
  const [spinnerData, setSpinnerData] = useState(SpinnerAnimation);
  const [themeColorLoaded, setThemeColorLoaded] = useState(false);

  useEffect(() => {
    if (!themeColorLoaded && themeContext?.site.colors.textContrastOnAccentPrimary) {
      setSpinnerData(
        new ThemedSpinner(themeContext.site.colors.textContrastOnAccentPrimary).Animation,
      );
      setThemeColorLoaded(true);
    }
  }, [
    themeColorLoaded,
    hash(themeContext),
  ]);

  return (
    <Player
      autoplay={true}
      loop={true}
      src={JSON.stringify(spinnerData)}
      style={style}
    />
  );
};

export default Spinner;
