import styled, { css } from 'styled-components';
import { isSafari } from 'utils';

export interface StyleProps {
  disabled?: boolean;
  fullwidth?: boolean;
  maxWidth?: string;
}

export const AspectRatioContainer = styled.div<StyleProps>`
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  ${({ fullwidth }) => fullwidth && 'width: 100%;'}
  ${({ disabled }) => disabled && `
    width: 100%;
    height: 100%;
  `}
`;

export const AspectRatioContent = styled.div<{ aspectRatio: number, disabled: boolean, height?: number }>`
  position: relative;
  ${({ disabled, aspectRatio, height }) => disabled ? css`
    width: 100%;
    height: 100%;
  ` : css`
    padding-bottom: ${aspectRatio}%;
    ${isSafari && `height: ${height}px;`}
    & > * {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  `}
`;
