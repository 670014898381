import invariant from 'invariant';
import get from 'lodash/get';
import sample from 'lodash/sample';
import { IObject, ISite, ITheme, PageType } from 'models';
import INavigation from 'models/INavigation';
import IPlan, { PLAN_TYPE, UNIT_TYPE } from 'models/IPlan';
import qs from 'qs';
import { MOCK_SITE_ID, MOCK_SITE_SLUG, MOCK_OBJECT_ID, generateTheme } from 'test/utils/testUtils';


declare global {
  // tslint:disable:next-line interface-name
  interface Window {
    INIT: IInit;
  }

  interface Storage {
    /**
     * @deprecated Use services/persistence instead.
     */
    clear(): void;
    /**
     * @deprecated Use services/persistence instead.
     */
    getItem(key: string): string | null;
    /**
     * @deprecated Use services/persistence instead.
     */
    key(index: number): string | null;
    /**
     * @deprecated Use services/persistence instead.
     */
    removeItem(key: string): void;
    /**
     * @deprecated Use services/persistence instead.
     */
    setItem(key: string, value: string): void;
  }
}

export interface IInit {
  ADMIN_QUEUES: string[];
  ADMIN_THEME: ITheme;
  AMAZON_CLIENT_ID?: string;
  BASE_PATH: string;
  CHAT_QUEUES: string[];
  CLIENT_JWT_PUBLIC_KEY: string;
  COMMUNITY_SHOW_SERVICE: string;
  ENVIRONMENT: string;
  FIREBASE_API_KEYS_MAP_JSON: {
    [key: string]: string;
  };
  FIREBASE_PRIMARY_SHARD: string;
  GEO_FENCED_SLUGS?: string[];
  GOOGLE_CLOUD_STORAGE_VIDEO_UPLOAD_URL?: string;
  GOOGLE_MAPS_API_KEY?: string;
  INSIGHTS_BASE_URL: string;
  LEGACY_DOMAIN: string;
  LEGACY_QUEST_CLAIM_BASE_URL?: string;
  MAESTRO_HOSTNAMES?: string[];
  MAESTRO_STUDIO_BASE_URL: string;
  MEDIA_ASSETS_BASE_URL?: string;
  MODE: 'staging' | 'production' | 'development';
  NAMESPACE: string;
  NAVIGATION?: INavigation
  OBJECT: null | IObject;
  OVERLAY_QUEUES: string[];
  PLAN: IPlan;
  RECAPTCHA_SITE_KEY: string;
  SEGMENT_WRITE_KEY: string;
  SERVICE_BASE_URL: string;
  SITE: ISite;
  SITE_ID: string;
  SITE_SHARDS: string[];
  STATIC_BASE_URL: string;
  STRIPE_PUBLISHABLE_KEY: string;
  STRIPE_SS_PUBLISHABLE_KEY: string;
  STUDIO_APP_FLAVOR: 'maestro' | 'gettr';
  THEME: ITheme;
  TWITCH_CLIENT_ID?: string;
  TYPEFORM_FIRST_SSU_LOGIN_FORM_ID: string;
  UPLOAD_SERVICE_BASE_URL?: string;
  USE_FIREBASE_EMULATOR?: string;
}

// TODO: Less janky way of filling this out in test environment.
const INIT: IInit = !window.INIT && (process.env.NODE_ENV === 'test' ||
  process.env.NODE_ENV === 'development') ? // Note that this check will be compiled out in prod.
  {
    ADMIN_QUEUES: ['fake_admin_queue'],
    BASE_PATH: '/fake_site_slug',
    CHAT_QUEUES: ['fake_test_queue'],
    CLIENT_JWT_PUBLIC_KEY: 'fake_public_token',
    COMMUNITY_SHOW_SERVICE: 'true',
    ENVIRONMENT: 'staging',
    FIREBASE_API_KEYS_MAP_JSON: { lessthan3: 'fakeApiKey' },
    FIREBASE_PRIMARY_SHARD: 'fake-primary-shard',
    GOOGLE_MAPS_API_KEY: 'AIzaSyB54bqXKGo2I2XSMeR-8JRi9FP-4rngGR8',
    INSIGHTS_BASE_URL: 'https://insights.maestro.io',
    LEGACY_DOMAIN: 'www.maestro.io', // TODO: Using 'silver.maestro.io' breaks tests lolololol
    MAESTRO_HOSTNAMES: ['sapphire.maestro.io', 'maestro.tv', 'www.videostreamer.net'],
    MAESTRO_STUDIO_BASE_URL: '',
    STUDIO_APP_FLAVOR: 'maestro',
    MODE: 'staging',
    NAMESPACE: 'maestro',
    NAVIGATION: undefined,
    OBJECT: {
      _id: MOCK_OBJECT_ID,
      collection: 'pages',
      site_id: MOCK_SITE_ID,
      data: {} as IObject['data'],
      renderers: {},
      slug: MOCK_SITE_SLUG,
      type: PageType.CHANNEL,
      baseLanguage: 'en-US',
    },
    OVERLAY_QUEUES: ['fake_test_overlay_queue'],
    PLAN: {
      _id: 'fake_plan_id',
      colors: {
        background: '#000000',
        baseColor: '#000000',
        mobileExpand: '#000000',
      },
      description: 'fake_plan_description',
      name: 'fake_plan_name',
      type: PLAN_TYPE.SelfService,
      payoutTerms: {
        entitlements: {
          revenueShare: 0.5,
          viewerServiceCharge: 0.5,
        },
        shopify: {
          revenueShare: 0.5,
          viewerServiceCharge: 0.5,
        },
        tips: {
          revenueShare: 0.5,
          viewerServiceCharge: 0.5,
        },
      },
      term: 'monthly',
      productType: 'maestro',
      planItems: [
        {
          name: 'fake_saas_rates',
          stripePriceId: 'fake_stripe_price_id',
          type: 'general',
          unit: UNIT_TYPE.baseSaasFee,
          allottedQuantity: 1,
          unitPrice: 1,
        },
      ],
      planFeatures: {
        cloudDataExport: true,
        customDomain: true,
        customerSuccessManager: true,
        developerTools: true,
        eventSupport: true,
        freeEvents: true,
        maxAmountOfAdmins: 9,
        maxAmountOfChannels: 9,
        maxAmountOfPages: 9,
        removeMaestroBranding: true,
        serviceLevelAgreement: true,
        viewerSupport: true,
      },
    },
    SEGMENT_WRITE_KEY: 'fake_segment_key',
    TYPEFORM_FIRST_SSU_LOGIN_FORM_ID: 'fake_typeform_key',
    SERVICE_BASE_URL: 'https://api.maestro.io',
    STRIPE_PUBLISHABLE_KEY: 'pk_test_exJdmE3wl40yzhJmAkjtDIWx',
    STRIPE_SS_PUBLISHABLE_KEY: 'pk_test_51JSoKBBH7IwBUn8Jq21U1cnPDOv4jowQIJVxBJMRkEkwkxw5tn0Y53w9wphObzoxwXNnRO2UyBZRMwdbglXVTPht00KL9palUW',
    SITE: {
      _id: MOCK_SITE_ID,
      slug: MOCK_SITE_SLUG,
      settings: {
        domain: {
          url: 'www.maestro.io/site-slug',
        },
      },
      pageCounts: {
        channels: 1,
        landings: 0,
      },
    } as ISite,
    SITE_ID: MOCK_SITE_ID,
    SITE_SHARDS: ['fake-shard'],
    STATIC_BASE_URL: 'https://static.gcp.maestro.io',
    THEME: generateTheme(),
    ADMIN_THEME: generateTheme(),
    RECAPTCHA_SITE_KEY: 'fake_recaptcha_key',
  } :
  window.INIT;

export const generateInit = (): IInit => ({
  ...INIT,
});

invariant(
  INIT,
  'INIT object not found. Developer: Did you go to the Webpack dev server port by accident? :)',
);

const INITIAL_QUERY = qs.parse(window.location.search.slice(1).toLowerCase()) as Record<string, string>;

export const UBISOFT_CONNECT_SDK = 'https://ubistatic2-a.akamaihd.net/uplay-connect/v3/prod/default/sdk/connectSdkPublic.js';
export const ADMIN_QUEUE = INITIAL_QUERY.admin_queue_override || sample(INIT.ADMIN_QUEUES);
export const CHAT_QUEUE = INITIAL_QUERY.chat_queue_override || sample(INIT.CHAT_QUEUES);
export const CLIENT_JWT_PUBLIC_KEY = INIT.CLIENT_JWT_PUBLIC_KEY;
export const OVERLAY_QUEUE = INITIAL_QUERY.overlay_queue_override || sample(INIT.OVERLAY_QUEUES);
export const { BASE_PATH, FIREBASE_API_KEYS_MAP_JSON, SITE_ID } = INIT;
export const FIREBASE_PRIMARY_SHARD = INITIAL_QUERY.firebase_primary_override || INIT.FIREBASE_PRIMARY_SHARD;
export const SEGMENT_WRITE_KEY = INITIAL_QUERY.segment_write_key_override || INIT.SEGMENT_WRITE_KEY;
export const TYPEFORM_FIRST_SSU_LOGIN_FORM_ID = (INITIAL_QUERY.typeform_first_ssu_login_form_id_override || INIT.TYPEFORM_FIRST_SSU_LOGIN_FORM_ID);
export const SITE_SLUG = INIT.SITE.slug;
export const DEV_MODE = INIT.MODE !== 'production';
export const OBJECT = INIT.OBJECT;
export const COMMUNITY_SHOW_SERVICE = INIT.COMMUNITY_SHOW_SERVICE === 'true';
export const NAVIGATION = INIT.NAVIGATION;
export const ENVIRONMENT = INIT.ENVIRONMENT;

const urlParams = new URLSearchParams(window.location.search);
const embedStr = urlParams.get('embed') || '';
export const EMBED = Boolean(embedStr);
export const COMPOSITE_OVERLAYS = embedStr.includes('overlays');

export const TWITCH_CLIENT_ID = INIT.TWITCH_CLIENT_ID;

const ENABLE_REDUX_DEBUG_KEY = 'fine';
const ENABLE_PLAYER_LOGGING_KEY = 'player-logging';
const ENABLE_MAESTRO_LOGIN_KEY = 'login';
const START_SHOPIFY_CONNECTION_KEY = 'connectshop';

export const ENABLE_INSIGHTS_LOGGING = Object.keys(INITIAL_QUERY)
  .some((param) => /^log[-_]?in(sight|site)s$/i.test(param));
export const ENABLE_REDUX_DEBUG = INITIAL_QUERY[ENABLE_REDUX_DEBUG_KEY] === '1';
export const ENABLE_PLAYER_LOGGING = INITIAL_QUERY[ENABLE_PLAYER_LOGGING_KEY] === '1';
export const FORCE_ENABLE_MAESTRO_LOGIN = INITIAL_QUERY[ENABLE_MAESTRO_LOGIN_KEY] === 'email';
export const START_SHOPIFY_CONNECTION = INITIAL_QUERY[START_SHOPIFY_CONNECTION_KEY] || null;
export const FIREBASE_SHARD_OVERRIDE = INITIAL_QUERY.shard || null;
export const LEGACY_QUEST_CLAIM_BASE_URL = INITIAL_QUERY.legacy_quest_claim_override || INIT.LEGACY_QUEST_CLAIM_BASE_URL;
export const INITIAL_LOCALE = INITIAL_QUERY.locale ||
  get(INIT.SITE, 'settings.baseLanguage') ||
  get(INIT.SITE, 'settings.localization.baseLanguage') ||
  'en';
export const NO_GDPR = 'nopop' in INITIAL_QUERY;

export const LEGACY_DOMAIN = INITIAL_QUERY.legacy_domain_override ||
  INIT.LEGACY_DOMAIN ||
  invariant(false, 'Missing LEGACY_DOMAIN');

export const SERVICE_BASE_URL = INITIAL_QUERY.service_base_override ||
  INIT.SERVICE_BASE_URL ||
  invariant(false, 'Missing SERVICE_BASE_URL');

// TODO: setup for development
export const UPLOAD_SERVICE_BASE_URL = INIT.UPLOAD_SERVICE_BASE_URL;

export const UBISOFT_APP_ID = '818017c4-2b7c-41f9-85be-00b1d9998a1e';
export const UBISOFT_GENOME_ID = '28e59d6c-cff6-4d20-8d16-504e3956b5ab';
export const UBISOFT_ENVIRONMENT = 'PROD';

export const BOWLTV_LOGOUT_URL = 'https://signon.bowl.com/connect/endsession';

export const AWS_STARFRUIT_PROVIDER_NAME = 'AWS Starfruit and MnE';
export const PHENIX_RTS_PROVIDER_NAME = 'phenix';

export const QUEST_SERVICE_OVERRIDE = INITIAL_QUERY.quest_service_override || null;

export const ENABLE_CONCURRENT_RENDERING = INITIAL_QUERY.concurrent !== '0';

export const LIBRARY_OVERRIDE = INITIAL_QUERY.library_override || null;

export const SOCIAL_SERVICE_BASE_URL = INITIAL_QUERY.social_override ||
  `${SERVICE_BASE_URL}/social/v1`;

export const FORCE_NEW_ADMIN = Boolean(INITIAL_QUERY.new_admin === '1');

export const FORCE_OLD_ADMIN = Boolean(INITIAL_QUERY.old_admin === '1');

// TODO: setup for development
export const GOOGLE_CLOUD_STORAGE_VIDEO_UPLOAD_URL = INIT.GOOGLE_CLOUD_STORAGE_VIDEO_UPLOAD_URL;

export const GOOGLE_MAPS_API_KEY = INIT.GOOGLE_MAPS_API_KEY;

export const RECAPTCHA_SITE_KEY = INIT.RECAPTCHA_SITE_KEY;

export const BOWLTV_PRODUCTION_DOMAIN = 'https://www.bowltv.com';

export const AUTH_SERVICE_BASE_URL = INITIAL_QUERY.auth_service_override ||
  `${SERVICE_BASE_URL}/auth/v2`;

export const ACHIEVEMENT_SERVICE_BASE_URL = INITIAL_QUERY.achievement_service_override ||
  `${SERVICE_BASE_URL}/achievements/v2`;

export const POLL_SERVICE_BASE_URL = INITIAL_QUERY.poll_service_override ||
  `${SERVICE_BASE_URL}/poll/v3`;

export const ACTIVE_USER_SERVICE_URL = INITIAL_QUERY.active_user_override ||
  `${SERVICE_BASE_URL}/active/v2`;

export const COLLECT_SERVICE_BASE_URL = INITIAL_QUERY.collect_service_override ||
  `${SERVICE_BASE_URL}/collect-service/v1`;

export const INSIGHTS_SERVICE_BASE_URL = INITIAL_QUERY.insights_service_override ||
  `${SERVICE_BASE_URL}/insights/v1`;

// TODO: Consolidate
export const POLL_ANSWER_SERVICE_BASE_URL = INITIAL_QUERY.poll_answer_service_override ||
  `${SERVICE_BASE_URL}/poll-answer/v3`;

// TODO: Consolidate
export const POLL_RESPONSE_SERVICE_BASE_URL = INITIAL_QUERY.poll_response_service_override ||
  `${SERVICE_BASE_URL}/poll-response/v3`;

export const ADMIN_SERVICE_BASE_URL = INITIAL_QUERY.admin_service_override ||
  `${SERVICE_BASE_URL}/admin/v1`;

export const BROADCAST_SERVICE_BASE_URL = INITIAL_QUERY.broadcast_service_override ||
  `${SERVICE_BASE_URL}/broadcast/v4`;

export const ADMIN_V3_BASE_URL = INITIAL_QUERY.admin_v3_override ||
  `${SERVICE_BASE_URL}/admin/v3`;

export const ADS_SERVICE_BASE_URL = INITIAL_QUERY.ads_service_override ||
  `${SERVICE_BASE_URL}/ads/v1`;

export const BILLING_SERVICE_BASE_URL = INITIAL_QUERY.billing_service_override ||
  `${SERVICE_BASE_URL}/billing/v1`;

export const SITE_SETTING_SERVICE_BASE_URL = INITIAL_QUERY.site_setting_service_override ||
  `${SERVICE_BASE_URL}/site-setting/v1`;

export const TAX_SERVICE_BASE_URL = INITIAL_QUERY.tax_service_override ||
  `${SERVICE_BASE_URL}/tax/v1`;

export const INVOICE_SERVICE_BASE_URL = INITIAL_QUERY.invoice_service_override ||
  `${SERVICE_BASE_URL}/invoice/v1`;

export const WALLET_SERVICE_BASE_URL = INITIAL_QUERY.wallet_service_override ||
  `${SERVICE_BASE_URL}/wallet/v1`;

export const SHOPIFY_SERVICE_BASE_URL = INITIAL_QUERY.shopify_service_override || `${SERVICE_BASE_URL}/shopify/v1`;

export const GATE_SERVICE_BASE_URL = INITIAL_QUERY.gate_service_override ||
  `${SERVICE_BASE_URL}/gate/v1`;

export const ACCOUNTS_SERVICE_BASE_URL = INITIAL_QUERY.accounts_override ||
  `${SERVICE_BASE_URL}/account/v3`;

export const ACCESSCODE_SERVICE_BASE_URL = INITIAL_QUERY.accesscode_service_override ||
  `${SERVICE_BASE_URL}/accesscode/v1`;

export const CLAIM_STATUS_SERVICE_BASE_URL = INITIAL_QUERY.claim_status_override ||
  `${SERVICE_BASE_URL}/claimstatus/v3`;

export const PANEL_SERVICE_BASE_URL = INITIAL_QUERY.panel_service_override ||
  `${SERVICE_BASE_URL}/panel/v3`;

export const SUBSCRIPTION_V2_BASE_URL = INITIAL_QUERY.subscription_v2_override ||
  `${SERVICE_BASE_URL}/subscription/v2`;

export const QUEST_BASE_URL = QUEST_SERVICE_OVERRIDE ||
  `${SERVICE_BASE_URL}/quest/v2`;

export const TIPPING_BASE_URL = INITIAL_QUERY.tipping_override ||
  `${SERVICE_BASE_URL}/tip/v1`;

export const API_KEY_BASE_URL = INITIAL_QUERY.api_key_override || `${SERVICE_BASE_URL}/api-key/v1`;

export const WEBHOOKS_BASE_URL = INITIAL_QUERY.webhooks_override || `${SERVICE_BASE_URL}/webhooks/v1`;

export const POST_AUTH_REDIRECT = INITIAL_QUERY.post_auth_redirect || null;

export const HIDE_MOBILE_VIDEO_FOOTER = Boolean(INITIAL_QUERY.owl_embed === '1');

export const SITE_SERVICE_BASE_URL = INITIAL_QUERY.site_override
  || `${SERVICE_BASE_URL}/site/v1`;

export const MEDIA_SERVICE_BASE_URL = INITIAL_QUERY.media_override || `${SERVICE_BASE_URL}/media/v1`;

// TODO: setup for development
export const MEDIA_ASSETS_BASE_URL = INITIAL_QUERY.media_assets_override || INIT.MEDIA_ASSETS_BASE_URL;

export const USER_PROFILE_SERVICE_BASE_URL = INITIAL_QUERY.profile_override || `${SERVICE_BASE_URL}/userprofile/v1`;
export const PLAYLIST_SERVICE_BASE_URL = INITIAL_QUERY.playlist_override || `${SERVICE_BASE_URL}/playlist/v1`;

export const CHAT_SERVICE_BASE_URL = INITIAL_QUERY.chat_service_override ||
  `${SERVICE_BASE_URL}/chat/v1`;

export const VALIDATE_SERVICE_BASE_URL = INITIAL_QUERY.validate_override ||
  `${SERVICE_BASE_URL}/validate/v1`;

export const CANIPLAY_SERVICE_BASE_URL = INITIAL_QUERY.caniplay_override ||
  `${SERVICE_BASE_URL}/caniplay/v1`;

export const PAGE_SERVICE_BASE_URL = INITIAL_QUERY.page_service_override ||
  `${SERVICE_BASE_URL}/page/v2`;

export const PAGE_SERVICE_V3_BASE_URL = INITIAL_QUERY.page_service_v3_override ||
  `${SERVICE_BASE_URL}/page/v3`;

export const STREAM_SERVICE_BASE_URL = INITIAL_QUERY.stream_service_override ||
  `${SERVICE_BASE_URL}/stream/v1`;

export const EPICGAMES_SERVICE_BASE_URL = INITIAL_QUERY.epicgames_service_override ||
  `${SERVICE_BASE_URL}/epicgames/v1`;

export const STREAM_PROVIDER_SERVICE_BASE_URL = INITIAL_QUERY.stream_provider_service_override ||
  `${SERVICE_BASE_URL}/streamprovider/v1`;

export const CONTRACT_SERVICE_BASE_URL = INITIAL_QUERY.contract_service_override ||
  `${SERVICE_BASE_URL}/contract/v1`;

export const BALANCE_TRANSACTION_SERVICE_BASE_URL = INITIAL_QUERY.balance_transaction_service_override ||
  `${SERVICE_BASE_URL}/balancetransaction/v1`;

export const BUNDLE_SERVICE_SERVICE_BASE_URL = INITIAL_QUERY.bundle_service_override || `${SERVICE_BASE_URL}/bundle/v1`;

export const THEMES_SERVICE_SERVICE_BASE_URL = INITIAL_QUERY.themes_service_override || `${SERVICE_BASE_URL}/themes/v1`;

export const NAVIGATION_SERVICE_BASE_URL = INITIAL_QUERY.navigation_service_override || `${SERVICE_BASE_URL}/navigation/v1`;

export const VIDEO_SERVICE_BASE_URL = INITIAL_QUERY.video_service_override || `${SERVICE_BASE_URL}/video/v3`;

export const NOTIFICATION_SERVICE_BASE_URL = INITIAL_QUERY.notification_service_override || `${SERVICE_BASE_URL}/notification/v1`;

export const INVITE_SERVICE_BASE_URL = INITIAL_QUERY.invite_service_override || `${SERVICE_BASE_URL}/invite/v1`;

export const PLAN_SERVICE_BASE_URL = INITIAL_QUERY.plan_service_override || `${SERVICE_BASE_URL}/plan/v1`;

export const PAYOUT_SERVICE_BASE_URL = INITIAL_QUERY.payout_service_override || `${SERVICE_BASE_URL}/payout/v1`;

export const CUSTOMER_SERVICE_BASE_URL = INITIAL_QUERY.customer_service_override || `${SERVICE_BASE_URL}/customer/v1`;

export const LOCALIZATION_SERVICE_BASE_URL = INITIAL_QUERY.localization_service_override || `${SERVICE_BASE_URL}/localization/v2`;

export const EVENT_SERVICE_BASE_URL = INITIAL_QUERY.event_service_override || `${SERVICE_BASE_URL}/event/v1`;

export const IFRAME_SDK_URL = INITIAL_QUERY.iframe_sdk;

export const PLATFORM = INITIAL_QUERY.platform;

export const OWL_SITE_ID = '5c2d47ac0fc1f00069361a89';
export const FORTNITE_SITE_ID = '5d65aef0a64db800a50bd6f0';

export const MAESTRO_DOMAIN = get(INIT.SITE, 'settings.domain.url');

export const MAESTRO_HOSTNAMES = [ ...(INIT.MAESTRO_HOSTNAMES || []), 'maestro-web-service' ];

const isCustomDomain = (/^(?!www.maestro.(io|tv))/.test(get(INIT.SITE, 'settings.domain.url')) &&
                      !/(maestro.(io|tv)$)/.test(window.location.host)) ||
                      (/^(?!www.videostreamer.net)/.test(get(INIT.SITE, 'settings.domain.url')) &&
                      !/(videostreamer.net$)/.test(window.location.host));
const localHostWithoutPort = window.location.host.includes(':') ? window.location.host.split(':')[0] : window.location.host;
const isUsingMaestroHostname = INIT.MAESTRO_HOSTNAMES?.indexOf(localHostWithoutPort) !== -1;

export const isLiveSite = (isCustomDomain && !isUsingMaestroHostname);

const E2E_SERVICE_DOMAIN = isLiveSite ?
  'https://api.maestro.io' :
  SERVICE_BASE_URL;

export const END_TO_END_SERVICE_BASE_URL = INITIAL_QUERY.e2e_service_override ||
  INITIAL_QUERY.end_to_end_service_override ||
  `${E2E_SERVICE_DOMAIN}/end-to-end/v3`;

export const REFERER_URL = `${window.location.origin}`;

export const STRIPE_PUBLISHABLE_KEY = (
  qs.parse(window.location.search.slice(1)).stripe_publishable_key_override ||
  INIT.STRIPE_PUBLISHABLE_KEY);

export const STRIPE_SS_PUBLISHABLE_KEY = (
  qs.parse(window.location.search.slice(1)).stripe_ss_publishable_key_override ||
  INIT.STRIPE_SS_PUBLISHABLE_KEY);

export const STRIPE_AUTHORIZATION_URL = INITIAL_QUERY.stripe_authorization ||
  'https://connect.stripe.com/oauth/authorize';

// Can't use INITIAL_QUERY because stripe_client_id is case sensitive
export const STRIPE_CLIENT_ID = qs.parse(window.location.search.slice(1)).stripe_client_id_override ||
  'ca_AfVbuK9ZQZyHh0pHJcayAuLCtQ6hRNOy';

export const EPG_SERVICE_BASE_URL = INITIAL_QUERY.epg_override || `${SERVICE_BASE_URL}/epg/v2`;

export const COMMUNITY_SERVICE_BASE_URL = INITIAL_QUERY.community_override || `${SERVICE_BASE_URL}/community/v1`;

export const SIGNUP_SERVICE_BASE_URL =
  INITIAL_QUERY.signup_service_override || `${SERVICE_BASE_URL}/signup/v1`;

export const IS_MOBILE = (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);
export const INVITE_TOKEN = INITIAL_QUERY.invite;
export const REGISTER_TOKEN = INITIAL_QUERY.registerinvite;

export const MAESTRO_STUDIO_BASE_URL = INITIAL_QUERY.studio_override || INIT.MAESTRO_STUDIO_BASE_URL;
export const STUDIO_APP_FLAVOR = INITIAL_QUERY.studio_app_flavor_override || INIT.STUDIO_APP_FLAVOR;
export const SITES_WITH_ANALYTICS_TURNED_OFF = ['5f44215cd2e733002d91e5e9'];
export const STATIC_BASE_URL = INIT.STATIC_BASE_URL;

export const USE_FIREBASE_EMULATOR = INIT.USE_FIREBASE_EMULATOR;

export const AMAZON_CLIENT_ID = INIT.AMAZON_CLIENT_ID;
export const INSIGHTS_BASE_URL = INIT.INSIGHTS_BASE_URL;

export const TOKEN_SERVICE_BASE_URL = INITIAL_QUERY.token_service_override ||
  `${SERVICE_BASE_URL}/token/v1`;

export const NAMESPACE = INITIAL_QUERY.namespace_override || INIT.NAMESPACE;

export const USE_AMAZON_SERIES_KEY = '_bm';
export const USE_AMAZON_SERIES_ID = INITIAL_QUERY[USE_AMAZON_SERIES_KEY] || null;

export const MARKETING_SITE_ID = '6221440f76b5040028b49bbd';
export const IS_MARKETING_SITE = INITIAL_QUERY.mimic_marketing === '1' || (INIT.SITE_ID === MARKETING_SITE_ID);

export const IS_SITE_READY_MAX_RETRIES =
  INITIAL_QUERY?.max_retries_override &&
  !Number.isNaN(INITIAL_QUERY?.max_retries_override)
    ? Number(INITIAL_QUERY?.max_retries_override)
    : 22;
