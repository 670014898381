import React, { useMemo } from 'react';
import { Popover, Sidetab, PopupButton, SliderButton, Widget } from '@typeform/embed-react';
import { useDispatch, useSelector } from 'react-redux';
import { getForms } from 'services/typeform/selectors';
import { ITypeformData, OnSubmitParams } from 'services/typeform/interfaces';

export default function TypeformPortal() {
  const formList: ITypeformData[] = useSelector(getForms);
  const dispatch = useDispatch();
  const TYPEFORM_PORTAL_MAP = {
    popOver: Popover,
    sideTab: Sidetab,
    popUpButton: PopupButton,
    sliderButton: SliderButton,
    widget: Widget,
  };

  const currentForm = useMemo(() => formList[0] || [], [formList]);

  const {
    formKey,
    formType,
    customIcon,
    onClose,
    onSubmit,
    style,
    onReady,
    onQuestionChanged,
    onHeightChanged,
    onEndingButtonClicked,
    buttonText,
    ...otherProps
  } = currentForm;

  const submitForm = (params: OnSubmitParams) => {
    if (onSubmit) {
      dispatch(onSubmit(params));
    }
  };

  const closeForm = () => {
    if (onClose) {
      dispatch(onClose());
    }
  };

  const onFormReady = () => {
    if (onReady) {
      dispatch(onReady());
    }
  };

  const onFormQuestionChanged = () => {
    if (onQuestionChanged) {
      dispatch(onQuestionChanged());
    }
  };

  const onFormHeightChanged = () => {
    if (onHeightChanged) {
      dispatch(onHeightChanged());
    }
  };

  const onFormEndingButtonClicked = () => {
    if (onEndingButtonClicked) {
      dispatch(onEndingButtonClicked());
    }
  };

  const Typeform = (props : any) => useMemo(() => {
    const Component = (TYPEFORM_PORTAL_MAP as any)[formType || ''];
    if (!Component) {
      return null;
    }
    return <Component {...props} />;
  }, [formList]);

  return (
    <Typeform
      buttonText={buttonText}
      id={formKey}
      style={style}
      onSubmit={submitForm}
      onClose={closeForm}
      customIcon={customIcon}
      onReady={onFormReady}
      onQuestionChanged={onFormQuestionChanged}
      onHeightChanged={onFormHeightChanged}
      onEndingButtonClicked={onFormEndingButtonClicked}
      {...otherProps}
    />
  );
}
