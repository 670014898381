import React from 'react';
import {
  CardContainer,
  CardTitle,
  CardDescription,
  CheckMarkIcon,
  GenericIcon,
} from './styles';
import TranslatedText from 'components/i18n/TranslatedText';

interface ICard {
  descriptionKey: string;
  iconKey: string;
  onClick: (value: any) => void;
  selected: boolean;
  titleKey: string;
  width?: string;
}
export default function Card({
  iconKey,
  titleKey,
  descriptionKey,
  selected,
  onClick,
  'data-testid': testId,
  width,
}: ICard & { ['data-testid']: string }) {
  return (
    <CardContainer selected={selected} onClick={onClick} data-testid={testId} width={width}>
      {selected && <CheckMarkIcon name="circledCheckmark" />}
      <GenericIcon name={iconKey} />
      <CardTitle>
        <TranslatedText stringKey={titleKey} />
      </CardTitle>
      <CardDescription>
        <TranslatedText stringKey={descriptionKey} />
      </CardDescription>
    </CardContainer>
  );
}
